import React from "react";
import { useLocation } from "react-router-dom";
import { services } from "../constants/services";

export default function Header() {
  const location = useLocation();

  // Extracting the page name from the URL
  const path = location.pathname;
  const pageName = path.substring(path.lastIndexOf("/") + 1);

  return (
    <header id="masthead" className="header prt-header-style-01">
      <div id="site-header-menu" className="site-header-menu">
        <div className="site-header-menu-inner prt-stickable-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="headermain-block d-flex align-items-center justify-content-between">
                  <div className="site-navigation d-flex align-items-center">
                    <div className="site-branding">
                      <h1>
                        <a
                          className="home-link"
                          href="/"
                          title="Radiology"
                          rel="home"
                        >
                          <img
                            id="logo-img"
                            height="100"
                            width="300"
                            className="img-fluid auto_size"
                            src="/images/PrimePrecisionLogo.png"
                            alt="logo-img"
                          />
                        </a>
                      </h1>
                    </div>
                  </div>

                  <div className="d-flex align-items-center">
                    <div className="menu-main-block">
                      <div className="menu-link">
                        <div className="btn-show-menu-mobile menubar menubar--squeeze">
                          <span className="menubar-box">
                            <span className="menubar-inner"></span>
                          </span>
                        </div>

                        <nav className="main-menu menu-mobile" id="menu">
                          <ul className="menu">
                            <li
                              className={`mega-menu-item ${
                                pageName === "" ? "active" : ""
                              }`}
                            >
                              <a href="/" className="mega-menu-link">
                                Home
                              </a>
                            </li>
                            <li
                              className={`mega-menu-item ${
                                pageName === "about" ? "active" : ""
                              }`}
                            >
                              <a href="/about">About us</a>
                              <ul className="mega-submenu">
                                <li>
                                  <a href="/about">Our Story</a>
                                </li>
                                <li
                                  className={
                                    pageName === "radiologists" ? "active" : ""
                                  }
                                >
                                  <a href="/radiologists">Our Radiologists</a>
                                </li>
                                <li
                                  className={
                                    pageName === " our-staff" ? "active" : ""
                                  }
                                >
                                  <a href="/our-staff">Our Key Staff</a>
                                </li>

                                <li
                                  className={
                                    pageName === "terms-condition"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a href="/terms-condition">
                                    Terms & conditions
                                  </a>
                                </li>
                                <li
                                  className={
                                    pageName === "privacy" ? "active" : ""
                                  }
                                >
                                  <a href="/privacy">Privacy Policy</a>
                                </li>
                              </ul>
                            </li>
                            <li
                              className={`mega-menu-item ${
                                pageName === "services" ? "active" : ""
                              }`}
                            >
                              <a href="/services">Services</a>
                              <div className="mega-submenu-services">
                                <div className="container-fluid">
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <ul>
                                        {services[0].services.map((sub, i) => (
                                          <li>
                                            <a
                                              className="servicesli"
                                              href={sub.link}
                                            >
                                              {sub.name}
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                    <div className="col-lg-3">
                                      <ul>
                                        {services[1].services.map((sub, i) => (
                                          <li>
                                            <a
                                              className="servicesli"
                                              href={sub.link}
                                            >
                                              {sub.name}
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                    <div className="col-lg-3">
                                      <ul>
                                        {services[2].services.map((sub, i) => (
                                          <li>
                                            <a
                                              className="servicesli"
                                              href={sub.link}
                                            >
                                              {sub.name}
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                    <div className="col-lg-3">
                                      <ul>
                                        {services[3].services.map((sub, i) => (
                                          <li>
                                            <a
                                              className="servicesli"
                                              href={sub.link}
                                            >
                                              {sub.name}
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li
                              className={`mega-menu-item ${
                                pageName === "for-referrers" ? "active" : ""
                              }`}
                            >
                              <a href="/for-referrers">For Referrers</a>
                            </li>
                            <li
                              className={`mega-menu-item ${
                                pageName === "contact-us" ? "active" : ""
                              }`}
                            >
                              <a href="/contact-us">Contact us</a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                    <div className="header_extra d-flex align-items-center">
                      <div className="header_btn">
                        <a
                          className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-fill prt-btn-color-skincolor"
                          href="/book-appointment"
                        >
                          Book Now
                        </a>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-fill prt-btn-color-skincolor-gr"
                          href="https://api.whatsapp.com/send?phone=26778594919"
                        >
                          Whatsapp
                        </a>
                      </div>
                      <div className="widget_info d-flex flex-row align-items-center justify-content-end">
                        <div className="widget_content">
                          <h3 className="widget_title">Dial to contact</h3>
                          <p className="widget_desc">
                            <a href="tel:+267316 3012">(+267) 316 3012</a>
                          </p>
                        </div>
                        <div className="widget_icon">
                          <i className="flaticon flaticon-customer-service"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
