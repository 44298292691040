import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { services } from "../../constants/servicesDetails";
import AppointmentForm from "../../components/AppointmentForm";
import CategorySubcategory from "../../components/CategorySubcategory";
import HeadingBanner from "../../components/HeadingBanner";

export default function PregnancyScan() {
  const service = services.find((service) => service.name === "3D-Pregnancy");

  // Handle case where the service is not found

  if (!service) {
    return <p>Service not found.</p>;
  }

  const styles = { fontSize: 23, marginBottom: 7 };
  return (
    <>
      <div className="page">
        <Header></Header>
        {/* site-main start */}
        <div className="site-main">
          <HeadingBanner displayname={service.displayname}></HeadingBanner>

          {/* service-section */}
          <section
            className="prt-row section-top-bg clearfix "
            style={{ paddingRight: 80, paddingLeft: 80 }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="prt-service-single-content-area">
                    <div className="prt-service-description">
                      <div className="prt-progressbar-main-block mt-30">
                        <div className="row align-items-center">
                          <div className="col-xl-8 col-lg-8">
                            <h3 style={styles}>What is a 3D Pregnancy Scan?</h3>
                            <p>
                            A 3D pregnancy scan uses advanced ultrasound technology to create three-dimensional images of your baby while in the womb. Unlike traditional 2D scans, which provide flat, two-dimensional images, a 3D scan offers a lifelike view of your baby’s features. This innovative imaging technique allows you to see the contours of your baby’s face, hands, and feet, offering a more comprehensive view of their development.                            </p>
                            <h3 style={styles} className="title">
                            Why Choose a 3D Pregnancy Scan?
                            </h3>
                            <ol>
                              <li><b>Enhanced Detail:</b> Experience your baby’s unique features with greater clarity and detail. Our 3D scans capture the nuances of their facial expressions and movements, bringing you closer to your baby before birth.</li>
                              <li><b>Emotional Connection:</b> Viewing your baby in 3D can strengthen the emotional bond between you and your little one. It’s a special moment for families to connect with their baby on a new level, making the pregnancy journey even more memorable.</li>
                              <li><b>Early Gender Reveal:</b> If you’re eager to find out your baby’s gender, a 3D scan can help. Our expert technicians can often determine the gender with high accuracy, allowing you to plan and prepare with confidence.</li>
                              <li><b>Beautiful Keepsakes:</b> The images and videos from your 3D scan make for treasured keepsakes. Share these precious moments with family and friends, or simply enjoy them as a lasting memory of this extraordinary time.</li>
                            </ol>
                            </div>
                          <div className="col-xl-4 col-lg-4">
                            <img
                              className="img-fluid border-rad_10"
                              src={service.image}
                              alt="primeprecision"
                            />
                          </div>
                          <div className="col-lg-12">
                            <h3 style={styles}>
                            HOW IT WORKS
                            </h3>
                            <ol>
                            <li><b>Image Acquisition:</b> Your imaging studies are captured using our advanced diagnostic equipment.</li>
                            <li><b>Secure Transmission:</b> Images are transmitted securely to our remote reporting system.</li>
                            <li><b>Radiologist Review:</b> Our radiologists review and analyze the images, providing a detailed and accurate interpretation.</li>
                            <li><b>Report Generation:</b> A comprehensive report is generated and securely transmitted to your healthcare provider</li>
                            <li><b>Follow-Up:</b> Your healthcare provider will discuss the results with you and plan any necessary next steps.</li>
                            </ol>
                          
                            <h3 style={styles}>After the examination</h3>
                            <p>
                              The radiologist will review the images and provide
                              a written report to your referring doctor. Please
                              settle your account on the day of the examination.
                              Please contact Prime Precision Imaging for an
                              appointment on (+267) 316 3012
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <CategorySubcategory
                        name={service?.category_name}
                      ></CategorySubcategory>
                    </div>
                    <div className="col-lg-12">
                      <AppointmentForm></AppointmentForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* service-section end */}
        </div>{" "}
        {/*  site-main end */}
        <Footer></Footer>
      </div>
    </>
  );
}
