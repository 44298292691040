// constants.ts
export const website = {
  COMPANY_NAME: "Prime Precision Imaging",
  EMAIL: "info@primeprecision.co.bw",
  HOME: {
    title:
      "Home | Prime Precision Imaging - Advanced Diagnostic Radiology Services",
    description:
      "Prime Precision Imaging offers state-of-the-art diagnostic radiology services including ultrasound, X-ray, fluoroscopy, bone densitometry, interventional radiology, and women imaging | Gaborone,Botswana",
    keywords:
      "imaging center Botswana, best imaging Gaborone, diagnostic imaging Botswana,radiology, diagnostic imaging, ultrasound, X-ray, fluoroscopy, bone densitometry, interventional radiology, mammography, teleradiology",
  },
  SERVICES: {
    title:
      "Our Services - Prime Precision Imaging | Diagnostic Imaging in Gaborone, Botswana",
    description:
      "Explore the diagnostic imaging services offered by Prime Precision Imaging in Gaborone, Botswana, including ultrasound, X-ray, fluoroscopy, interventional radiology, and more.",
    keywords:
      "imaging services Botswana, ultrasound Gaborone, X-ray services, fluoroscopy Botswana, interventional radiology, diagnostic imaging Gaborone",
  },
  ABOUT: {
    title:
      "About Us - Prime Precision Imaging | Leading Diagnostic Imaging in Gaborone, Botswana",
    description:
      "Learn about Prime Precision Imaging, the leading diagnostic imaging center in Gaborone, Botswana, offering advanced ultrasound, X-ray, fluoroscopy, and more.",
    keywords:
      "about Prime Precision Imaging, imaging center Botswana, diagnostic imaging Gaborone, radiology experts Botswana, medical imaging professionals",
  },
  CONTACT: {
    title:
      "Contact Us - Prime Precision Imaging | Diagnostic Imaging Center in Gaborone, Botswana",
    description:
      "Get in touch with Prime Precision Imaging, your trusted diagnostic imaging center in Gaborone, Botswana. Contact us for ultrasound, X-ray, fluoroscopy, and more.",
    keywords:
      "contact Prime Precision Imaging, imaging center Botswana, diagnostic imaging Gaborone, radiology contact Gaborone, medical imaging services contact, radiology Botswana",
  },
  DOCTORS: {
    title:
      "Our Doctors - Prime Precision Imaging | Expert Radiologists in Gaborone, Botswana",
    description:
      "Meet the expert radiologists and imaging specialists at Prime Precision Imaging in Gaborone, Botswana. Our doctors are dedicated to providing top-quality diagnostic care.",
    keywords:
      "our doctors Prime Precision Imaging, expert radiologists Botswana, imaging specialists Gaborone, diagnostic imaging team, radiology doctors Botswana, medical professionals Gaborone",
  },
  TEAM: {
    title:
      "Our Radiologists - Prime Precision Imaging | Leading Diagnostic Imaging in Gaborone, Botswana",
    description:
      "Learn about Prime Precision Imaging, the leading diagnostic imaging center in Gaborone, Botswana, offering advanced ultrasound, X-ray, fluoroscopy, and more.",
    keywords:
      "about Prime Precision Imaging, imaging center Botswana, diagnostic imaging Gaborone, radiology experts Botswana, medical imaging professionals",
  },
};
