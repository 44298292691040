import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet-async";
import { website } from "../constants/website";

export default function AboutUs() {
  return (
    <>
      <Helmet>
        <title>{website.ABOUT.title}</title>
        <meta name="description" content={website.ABOUT.description} />
        <meta name="keywords" content={website.ABOUT.keywords} />
      </Helmet>
      <div className="page">
        <Header></Header>

        <div className="prt-page-title-row style2" style={{ marginTop: 90 }}>
          <div className="prt-page-title-row-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 m-auto z-index-9">
                  <div className="prt-page-title-row-heading text-center ">
                    <div className="page-title-heading">
                      <h2 className="title">About US</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="site-main">
          <section className="prt-row about-section01 animation clearfix">
            <div className="container">
              {/*  row  */}
              <div className="row align-items-center">
                <div className="col-xl-5 col-lg-12 col-md-12">
                  {/*  prt_single_image-wrapper  */}
                  <div
                    className="position-relative aos-init aos-animate"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    <img
                      width="635"
                      height="670"
                      className="img-fluid border-rad_25"
                      src="images/About-us.png"
                      alt="primeprecision"
                    />
                  </div>
                  {/*  prt_single_image-wrapper end */}
                </div>
                <div className="col-xl-7 col-lg-12 col-md-12">
                  <div className="position-relative pl-30 res-1199-pl-0 res-1199-mt-30">
                    <div className="row row-equal-height">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        {/*  featured-contentbox  */}
                        <div className="featured-contentbox">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h3 className="title">
                                <i className="flaticon flaticon-asterisk-1"></i>{" "}
                                Who we are
                              </h3>
                            </div>
                            <div className="featured-desc">
                              <p>
                                At Prime Precision Imaging we are dedicated to
                                providing exceptional diagnostic imaging
                                services with a focus on accuracy, compassion,
                                and patient-centric care. Located [location],
                                our state-of-the-art facility is equipped with
                                advanced technology and staffed by experienced
                                radiologists, technologists, and support
                                personnel committed to delivering reliable
                                results and ensuring a positive patient
                                experience.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/*  featured-contentbox end */}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        {/*  featured-contentbox  */}
                        <div className="featured-contentbox">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h3 className="title">
                                <i className="flaticon flaticon-asterisk-1"></i>{" "}
                                Mision & vision
                              </h3>
                            </div>
                            <div className="featured-desc">
                              <p>
                                At Prime Precision Imaging, our mission is to
                                enhance healthcare outcomes through cutting-edge
                                diagnostic imaging, precise interventional
                                procedures, and compassionate, personalized
                                care. We are committed to setting the benchmark
                                for excellence in everything we do by
                                consistently adhering to the highest standards
                                of quality, professionalism, and ethical
                                integrity.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/*  featured-contentbox end */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  row end  */}
            </div>
          </section>
          <section className="prt-row prt-bg about-section01 clearfix bg-base-grey">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12">
                  <div className="prt-list-block">
                    <h2>Benefits:</h2>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <ul className="prt-list prt-list-style-icon-01">
                          <li className="prt-list-li-content">
                            <b>Patient-Centric Care:</b> We prioritize
                            personalized care that respects each patient’s
                            unique needs, ensuring a positive experience and
                            clear communication at every step.
                          </li>
                          <li className="prt-list-li-content">
                            <b>Excellence in Diagnostics:</b> Utilizing
                            state-of-the-art imaging technologies, we deliver
                            accurate and timely results that empower clinicians
                            to make well-informed decisions for optimal patient
                            care.
                          </li>
                          <li className="prt-list-li-content">
                            <b>Professionalism and Integrity:</b> Upholding
                            strict ethical standards, we approach every
                            interaction with transparency, respect, and a
                            commitment to professional excellence.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="prt-row info-section02 clearfix  ">
            <div className="container">
              <div className="row row-equal-height">
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <a href="/radiologists" className="featured-contentbox">
                    <div className="featured-content">
                      <div className="featured-title">
                        <h3>Our doctors</h3>
                      </div>
                      <div className="featured-desc">
                        <p>Our dedicated doctors are here for you</p>
                      </div>
                    </div>
                    <div className="featured-icon">
                      <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-size-lg prt-icon_element-color-gradientcolor">
                        <i className="flaticon flaticon-doctor"></i>
                      </div>
                      <div className="prt-position">
                        <a href="/radiologists">Doctors</a>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <a
                    href="https://maps.app.goo.gl/kGpxrUDc8QVeTtt87"
                    className="featured-contentbox"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="featured-content">
                      <div className="featured-title">
                        <h3>Our location</h3>
                      </div>
                      <div className="featured-desc">
                        <p>Visit us at our convenient location</p>
                      </div>
                    </div>
                    <div className="featured-icon">
                      <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-size-lg prt-icon_element-color-gradientcolor">
                        <i className="flaticon flaticon-home"></i>
                      </div>
                      <div className="prt-position">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://maps.app.goo.gl/kGpxrUDc8QVeTtt87"
                        >
                          Directions
                        </a>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <a href="/contact-us" className="featured-contentbox">
                    <div className="featured-content">
                      <div className="featured-title">
                        <h3>Urgent imaging results</h3>
                      </div>
                      <div className="featured-desc">
                        <p>Fast-track your lab tests with our urgent service</p>
                      </div>
                    </div>
                    <div className="featured-icon">
                      <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-size-lg prt-icon_element-color-gradientcolor">
                        <i className="flaticon flaticon-alarm"></i>
                      </div>
                      <div className="prt-position">
                        <a href="/contact-us">Contact us</a>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <a href="/book-appointment" className="featured-contentbox">
                    <div className="featured-content">
                      <div className="featured-title">
                        <h3>Appointments </h3>
                      </div>
                      <div className="featured-desc">
                        <p>Select a doctor and schedule an appointment</p>
                      </div>
                    </div>
                    <div className="featured-icon">
                      <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-size-lg prt-icon_element-color-gradientcolor">
                        <i className="flaticon flaticon-calendar"></i>
                      </div>
                      <div className="prt-position">
                        <a href="/book-appointment">Request to call</a>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer></Footer>
      </div>
    </>
  );
}
