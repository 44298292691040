import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import Team from "./pages/Team";
import ServicesDetails from "./pages/ServiceDetails";
import BookAppointment from "./pages/BookAppointment";
import TermsConditions from "./pages/TermsConditions";
import Privacy from "./pages/Privacy";
import Ultrasound from "./pages/services/Ultrasound";
import Fluoroscopy from "./pages/services/Fluoroscopy";
import BoneDensity from "./pages/services/BoneDensity";
import Xray from "./pages/services/Xray";
import AdvancedTechnology from "./pages/services/AdvancedTechnology";
import BreastUltrasound from "./pages/services/BreastUltrasound";
import Mammography from "./pages/services/Mammography";
import MinimallyInvasiveProcedure from "./pages/services/MinimallyInvasiveProcedure";
import PainManagement from "./pages/services/PainManagement";
import RemoteReporting from "./pages/services/RemoteReporting";
import ForReferrers from "./pages/ForReferrers";
import PregnancyScan from "./pages/services/PregnancyScan";
import OurKeyStaff from "./pages/OurKeyStaff";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/radiologists" element={<Team />} />
          <Route path="/details" element={<ServicesDetails />} />
          <Route path="/book-appointment" element={<BookAppointment />} />
          <Route path="/terms-condition" element={<TermsConditions />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/services/ultrasound" element={<Ultrasound />} />
          <Route
            path="/services/fluoroscopy"
            element={<Fluoroscopy></Fluoroscopy>}
          ></Route>
          <Route
            path="/services/bone-densitometry-dexa-scan"
            element={<BoneDensity />}
          ></Route>
          <Route path="/services/x-ray" element={<Xray />}></Route>
          <Route
            path="/services/advanced-technology"
            element={<AdvancedTechnology />}
          ></Route>
          <Route
            path="/services/breast-ultrasound"
            element={<BreastUltrasound />}
          ></Route>
          <Route path="/services/mammography" element={<Mammography />}></Route>
          <Route
            path="/services/minimally-invasive-procedure"
            element={<MinimallyInvasiveProcedure />}
          ></Route>
          <Route
            path="/services/pain-management"
            element={<PainManagement />}
          ></Route>
          <Route
            path="/services/remote-reporting"
            element={<RemoteReporting />}
          ></Route>
          <Route
            path="/services/3d-pregnancy-scan"
            element={<PregnancyScan />}
          ></Route>
          <Route path="/our-staff" element={<OurKeyStaff />}></Route>
          <Route path="/for-referrers" element={<ForReferrers />}></Route>
        </Routes>
      </Router>
    </>
  );
};
export default App;
