import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Banner from "../components/home/Banner";
import ContactInfo from "../components/home/ContactInfo";
import WorkingHours from "../components/home/WorkingHours";
import Services from "../components/home/Services";
import Stepper from "../components/home/Stepper";
import Map from "../components/home/Map";
import { Helmet } from "react-helmet-async";
import { website } from "../constants/website";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>{website.HOME.title}</title>
        <meta name="description" content={website.HOME.description} />
        <meta name="keywords" content={website.HOME.keywords} />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "DiagnosticLab",
            "name": "Prime Precision Imaging",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "plot 54734  grand union building CBD,unit 2B on the new lobatse road",
              "addressLocality": "Gaborone",
              "addressRegion": "Botswana",
              "postalCode": "Your Postal Code Here",
              "addressCountry": "BW"
            },
            "telephone": "+267 78594919",
            "url": "https://primeprecision.co.bw",
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": -24.658,
              "longitude": 25.912
            }
          }
        `}
        </script>
      </Helmet>

      <div className="page ">
        <Header></Header>
        <div className="main-box">
          <Banner></Banner>
        </div>

        <div className="site-main">
          <ContactInfo></ContactInfo>

          <WorkingHours></WorkingHours>

          <Services></Services>

          <Stepper></Stepper>

          {/* <NewEquip></NewEquip> */}

          {/* <Doctors></Doctors> */}

          <Map></Map>
        </div>

        <Footer></Footer>
      </div>
    </>
  );
}
