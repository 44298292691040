import React from "react";

export default function HeadingBanner({ displayname }: any) {
  return (
    <div className="prt-page-title-row style2" style={{ marginTop: 90 }}>
      <div className="prt-page-title-row-inner">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto z-index-9">
              <div className="prt-page-title-row-heading text-center ">
                <div className="page-title-heading">
                  <h2 className="title">{displayname}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
