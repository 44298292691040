import React from "react";

export default function WorkingHours() {
  return (
    <section className="prt-row prt-bg bg-base-grey about-section clearfix">
      <div className="container">
        <div className="row align-items-center mt-20 res-991-mt-0">
          <div className="col-xl-4 col-lg-12">
            <img
              className="img-fluid"
              src="images/working-hours.jpeg"
              alt="image-i"
            />
          </div>
          <div className="col-xl-8 col-lg-12">
            <div className="prt-bg bg-base-white ml-25 res-1199-ml-0 res-1199-mt-30">
              <div className="row align-items-center">
                <div className="col-lg-12 col-md-6">
                  <div className="prt-schedule-content">
                    <div>
                      <h2 className="title">Working hours</h2>
                      <p>
                        Our hours are required to accommodate your needs and
                        provide care when you need it most
                      </p>
                    </div>
                    <ul className="prt-list prt-schedule-list">
                      <li>
                        <span>Monday - Friday</span> 08:00hrs - 22:00hrs
                      </li>
                      <li>
                        <span>
                          Saturday - Sunday -{" "}
                          <small>
                            <span
                              className="focus"
                              style={{ color: "#3f9af7" }}
                            >
                              Public holidays
                            </span>
                          </small>{" "}
                        </span>
                        09:00hrs - 17:00hrs{" "}
                      </li>
                      <li>
                        {" "}
                        <a
                          className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-fill prt-btn-color-skincolor mr-20 mt-10"
                          href="/contact-us"
                        >
                          {" "}
                          Contact Now
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
