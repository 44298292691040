import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet-async";
import { website } from "../constants/website";

export default function ContactUs() {
  return (
    <>
      <Helmet>
        <title>{website.CONTACT.title}</title>
        <meta name="description" content={website.CONTACT.description} />
        <meta name="keywords" content={website.CONTACT.keywords} />
      </Helmet>
      <div className="page">
        <Header></Header>

        <div className="site-main">
          <section className="prt-row prt-bg bg-base-grey contact-section contact-border-top clearfix">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-8 col-md-10 col-sm-11 m-auto">
                  <div className="section-title title-style-center_text">
                    <div className="title-header">
                      <h3 className="prt-contact-pretitle">Contact</h3>
                      <h2 className="title">We’re here to help you</h2>
                    </div>
                  </div>

                  <div className="row g-0">
                    <div className="col-lg-5 col-md-5 col-sm-5">
                      <div className="prt-contact-link">
                        <h3>Call us at:</h3>
                        <p>
                          <a href="tel:+2673163012">+267-316-3012</a>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7">
                      <div className="prt-contact-link border-left res-575-mt-0">
                        <h3>Email us:</h3>
                        <p>
                          <a href="mailto:info@primeprecision.co.bw">
                            info@primeprecision.co.bw
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="prt_contact_widget_wrapper mt-55 res-991-mt-20">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="prt_contact-title-block bg-base-gradient">
                          <div className="section-title">
                            <div className="title-header text-base-white">
                              <h2 className="title">
                                Patient concerns &<br /> complaints
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="prt_content-block">
                        <div className="row g-0">
                          <div className="col-xl-6 col-lg-12">
                            <div className="pr-40 res-991-pr-0">
                              <p>
                                Prime Precision Imaging operates across several
                                locations, each offering specialized imaging
                                services.<br></br> Our services guide outlines
                                the specific imaging and diagnostic solutions
                                available at center. <br></br> For non-urgent
                                inquiries, feel free to reach out through the
                                contact form below.<br></br> We value your
                                feedback as it helps us continuously enhance our
                                services to provide top-tier care to our
                                patients and referrers.
                              </p>
                              <ul className="prt-list prt-schedule-list">
                                <li>
                                  <span>Monday - Friday</span> 08:00hrs -
                                  22:00hrs
                                </li>
                                <li>
                                  <span>Saturday - Sunday</span> 09:00hrs -
                                  17:00hrs
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-12">
                            <div
                              className="prt-contact-form"
                              data-aos="fade-up"
                              data-aos-duration="1000"
                            >
                              <h3>Fill form to reach us</h3>
                              <div className="prt-form-sep position-relative border-top mb-40 mt-30 res-575-mt-0 res-575-mb-0">
                                <div className="prt-icon">
                                  <i className="flaticon-arrow"></i>
                                </div>
                              </div>
                              <form
                                action="#"
                                className="query_form-1 wrap-form clearfix"
                                method="post"
                              >
                                <div className="row">
                                  <div className="col-md-6">
                                    <span className="text-input">
                                      <input
                                        name="name"
                                        type="text"
                                        value=""
                                        placeholder="Name"
                                      />
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <span className="text-input">
                                      <input
                                        name="phone"
                                        type="text"
                                        value=""
                                        placeholder="Phone Number"
                                      />
                                    </span>
                                  </div>
                                  <div className="col-md-12">
                                    <span className="text-input">
                                      <input
                                        name="email"
                                        type="text"
                                        value=""
                                        placeholder="Email"
                                      />
                                    </span>
                                  </div>

                                  <div className="col-md-12">
                                    <span className="text-input">
                                      <textarea
                                        name="message"
                                        placeholder="How can we help you?"
                                      ></textarea>
                                    </span>
                                  </div>

                                  <div className="col-md-12">
                                    <button
                                      className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-fill prt-btn-color-skincolor"
                                      type="submit"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer></Footer>
      </div>
    </>
  );
}
