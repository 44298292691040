import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { services } from "../../constants/servicesDetails";
import AppointmentForm from "../../components/AppointmentForm";
import CategorySubcategory from "../../components/CategorySubcategory";
import HeadingBanner from "../../components/HeadingBanner";

export default function MinimallyInvasiveProcedure() {
  const service = services.find((service) => service.id === "6");

  if (!service) {
    return <p>Service not found.</p>;
  }
  const styles = { fontSize: 23, marginBottom: 7 };
  return (
    <>
      <div className="page">
        <Header></Header>
        {/* site-main start */}
        <div className="site-main">
          <HeadingBanner displayname={service.displayname}></HeadingBanner>

          {/* service-section */}
          <section
            className="prt-row section-top-bg clearfix "
            style={{ paddingRight: 80, paddingLeft: 80 }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="prt-service-single-content-area">
                    <div className="prt-service-description">
                      <div className="prt-progressbar-main-block mt-30">
                        <div className="row align-items-center">
                          <div className="col-xl-8 col-lg-8">
                            <h3 style={styles}>PAIN MANAGEMENT PROCEDURES</h3>
                            <p>
                            We specialize in minimally invasive, image-guided procedures that offer relief from chronic pain. These techniques include:                             <br></br>
                            </p>
                            <ul>
                              <li><b>Epidural Steroid Injections:</b> Reduce inflammation and alleviate pain caused by conditions like herniated discs or spinal stenosis.</li>
                              <li><b>Facet Joint Injections: </b>Provide targeted relief for pain originating from the facet joints in the spine.</li>
                              <li><b>Nerve Blocks:</b> Interrupt pain signals from specific nerves to reduce pain and improve function.</li>
                              <li><b>Radiofrequency Ablation:</b> Uses heat generated by radio waves to destroy nerves that are causing pain, offering long-term relief.</li>
                            </ul>
                          </div>
                          <div className="col-xl-4 col-lg-4">
                            <img
                              className="img-fluid border-rad_10"
                              src={service.image}
                              alt="primeprecision"
                            />
                          </div>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <CategorySubcategory
                        name={service?.category_name}
                      ></CategorySubcategory>
                    </div>
                    <div className="col-lg-12">
                      <AppointmentForm></AppointmentForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* service-section end */}
        </div>{" "}
        {/*  site-main end */}
        <Footer></Footer>
      </div>
    </>
  );
}
