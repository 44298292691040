import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet-async";
import { website } from "../constants/website";

export default function TermsConditions() {
  return (
    <>
      <Helmet>
        <title>{website.ABOUT.title}</title>
        <meta name="description" content={website.ABOUT.description} />
        <meta name="keywords" content={website.ABOUT.keywords} />
      </Helmet>
      <div className="page">
        <Header></Header>

        <div className="prt-page-title-row style2" style={{ marginTop: 90 }}>
          <div className="prt-page-title-row-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 m-auto z-index-9">
                  <div className="prt-page-title-row-heading text-center ">
                    <div className="page-title-heading">
                      <h2 className="title">Terms & Conditions</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="site-main">
          <section className="prt-row banner-section bg-img1 prt-bg prt-bgimage-yes bg-base-dark clearfix">
            <div className="prt-row-wrapper-bg-layer prt-bg-layer bg-base-dark"></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 align-self-center mt-100 res-991-mt-0">
                  <div className="section-title style1">
                    <div className="title-header">
                      <h2 className="title">Introduction</h2>
                    </div>
                    <div className="title-desc">
                      <p>
                        Welcome to Prime Precision Imaging ("we," "our," or
                        "us"). By accessing or using our website [website URL]
                        and the services we offer, you agree to comply with and
                        be bound by the following Terms and Conditions. Please
                        read them carefully. If you do not agree with these
                        terms, you should not use our website or services.
                      </p>
                    </div>
                  </div>

                  <div className="section-title style1">
                    <div className="title-header">
                      <h4 className="title">Services Provided</h4>
                    </div>
                    <div className="title-desc">
                      <p>
                        We offer diagnostic imaging services, including but not
                        limited to X-rays, bone densitometry scan, and
                        ultrasounds. Our services are performed by licensed and
                        certified radiologic technologists and interpreted by
                        board-certified radiologists.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-4">
                  <div className="prt_single_image-wrapper_align style1 res-991-mt-30">
                    <img
                      width="632"
                      height="632"
                      className="img-fluid"
                      src="images/Terms and conditions.png"
                      alt="img"
                    />
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-lg-10">
                  <div className="section-title">
                    <div className="title-header">
                      <h4 className="title">Appointments and Scheduling</h4>
                      <ul>
                        <li>
                          <b>Appointment Booking:</b> Appointments can be
                          scheduled via our website, by phone, or through a
                          referral from a healthcare provider.
                        </li>
                        <li>
                          <b>Cancellation Policy:</b> Please provide at least 24
                          hours' notice for cancellations. Failure to do so may
                          result in a cancellation fee.
                        </li>
                        <li>
                          <b>Late Arrivals:</b> If you arrive late for your
                          appointment, we may need to reschedule your visit to
                          ensure timely service for all patients.
                        </li>
                      </ul>
                    </div>

                    <div className="title-header">
                      <h4 className="title">Patient Responsibilities</h4>
                      <ul>
                        <li>
                          <b>Information Accuracy:</b>It is your responsibility
                          to provide accurate and complete information regarding
                          your medical history and current medications.
                        </li>
                        <li>
                          <b>Insurance Information:</b> You must provide valid
                          insurance details at the time of your appointment.
                          Failure to do so may result in you being billed
                          directly for the services provided.
                        </li>
                      </ul>
                    </div>

                    <div className="title-header">
                      <h4 className="title">Payment and Insurance</h4>
                      <ul>
                        <li>
                          <b>Payment Terms:</b>Payment for services is due at
                          the time of service unless other arrangements have
                          been made. We accept various forms of payment,
                          including credit/debit cards and cash.
                        </li>
                        <li>
                          <b>Insurance Claims:</b> We will submit insurance
                          claims on your behalf if you provide accurate
                          insurance information. However, you are ultimately
                          responsible for any charges not covered by your
                          insurance.
                        </li>
                      </ul>
                    </div>

                    <div className="title-header">
                      <h4 className="title">Privacy and Confidentiality</h4>
                      <ul>
                        <li>
                          <b>Compliance:</b>We are committed to protecting your
                          personal and medical information in accordance with
                          the Ministry of Health and its regulations.
                        </li>
                        <li>
                          <b>Data Security:</b>We implement reasonable security
                          measures to protect your data from unauthorized
                          access, alteration, disclosure, or destruction.
                        </li>
                      </ul>
                    </div>

                    <div className="title-header">
                      <h4 className="title">Use of Website</h4>
                      <ul>
                        <li>
                          <b>Intellectual Property:</b>All content on our
                          website, including text, graphics, logos, and images,
                          is owned by PrimePrecision Imaging or licensed to us
                          and may not be used without our prior written consent.
                        </li>
                        <li>
                          <b>Website Accuracy:</b>While we strive to keep our
                          website accurate and up-to-date, we make no warranties
                          regarding the accuracy or completeness of the content.
                        </li>
                      </ul>
                    </div>

                    <div className="title-header">
                      <h4 className="title">Limitation of Liability</h4>
                      <p>
                        To the fullest extent permitted by law, Prime Precision
                        Imaging shall not be liable for any indirect,
                        incidental, special, or consequential damages arising
                        from your use of our services or website. Our total
                        liability for any claim related to our services or
                        website is limited to the amount paid for the specific
                        service in question.
                      </p>
                    </div>

                    <div className="title-header">
                      <h4 className="title">Changes to Terms</h4>
                      <p>
                        We may update these Terms and Conditions from time to
                        time. We will notify you of any significant changes by
                        posting the updated terms on our website. Your continued
                        use of our website and services following the posting of
                        changes constitutes your acceptance of those changes.
                      </p>
                    </div>

                    <div className="title-header">
                      <h4 className="title">Governing Law</h4>
                      <p>
                        These Terms and Conditions are governed by and construed
                        in accordance with the laws of Botswana, without regard
                        to its conflict of law principles.
                      </p>
                    </div>

                    <div className="title-header">
                      <h4 className="title">Contact Information</h4>
                      <p>
                        If you have any questions or concerns about these Terms
                        and Conditions, please contact us at:
                      </p>
                      <ul>
                        <li>
                          Address : Prime Precision Imaging plot 54374 CBD grand
                          union building
                        </li>
                        <li>Phone : +267 316 3012</li>
                        <li>Email : info@primeprecision.co.bw</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer></Footer>
      </div>
    </>
  );
}
