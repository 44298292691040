import React from "react";
interface Category {
  name: string;
  subcategories: any;
}
export default function CategorySubcategory({ name }: any) {
  const [activecategory, setActiveCategory] = React.useState("");
  const categories: Category[] = [
    {
      name: "Diagnostic Imaging",
      subcategories: [
        { name: "Ultrasound", link: "/services/ultrasound" },
        { name: "X-ray", link: "/services/x-ray" },
        { name: "Fluoroscopy", link: "/services/fluoroscopy" },
        {
          name: "Bone Densitometry (DEXA Scan)",
          link: "/services/bone-densitometry-dexa-scan",
        },
      ],
    },
    {
      name: "Women's Imaging",
      subcategories: [
        { name: "Mammography", link: "/services/mammography" },
        { name: "Breast Ultrasound", link: "/services/breast-ultrasound" },
        { name: "3D Pregnancy Scan", link: "/services/3d-pregnancy-scan" },
      ],
    },
    {
      name: "Imaging Guided Procedures",
      subcategories: [
        {
          name: "Minimally Invasive Procedures",
          link: "/services/minimally-invasive-procedure",
        },
        { name: "Pain Management", link: "/services/pain-management" },
      ],
    },

    {
      name: "Telemedicine and Teleradiology",
      subcategories: [
        { name: "Remote Reporting", link: "/services/remote-reporting" },
        { name: "Advanced Technology", link: "/" },
      ],
    },
  ];
  React.useEffect(() => {
    setActiveCategory(name);
  }, [name]);
  const toggleCategory = (category: string) => {
    setActiveCategory(category);
  };
  return (
    <div className="sidebar prt-row">
      <ul className="category-list">
        {categories.map((category, index) => (
          <li key={index}>
            <div
              className={`category ${
                activecategory === category.name ? "category-active" : ""
              }`}
              onClick={() => toggleCategory(category.name)}
            >
              {category.name}
            </div>
            <ul
              className={`subcategory-list ${
                activecategory === category.name ? "open" : ""
              }`}
            >
              {category.subcategories.map(
                (subcategory: any, subIndex: number) => (
                  <li key={subIndex}>
                    <a href={subcategory.link}>{subcategory.name}</a>
                  </li>
                )
              )}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}
