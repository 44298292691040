import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { services } from "../../constants/servicesDetails";
import AppointmentForm from "../../components/AppointmentForm";
import CategorySubcategory from "../../components/CategorySubcategory";
import Content from "../../components/Content";
import HeadingBanner from "../../components/HeadingBanner";

export default function BoneDensity() {
  const service = services.find(
    (service) => service.name === "bone-densitometry"
  );
  if (!service) {
    return <p>Service not found.</p>;
  }

  const styles = { fontSize: 23, marginBottom: 7 };
  return (
    <>
      <div className="page">
        <Header></Header>
        {/* site-main start */}
        <div className="site-main">
          <HeadingBanner displayname={service.displayname}></HeadingBanner>

          {/* service-section */}
          <section
            className="prt-row section-top-bg clearfix "
            style={{ paddingRight: 80, paddingLeft: 80 }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="prt-service-single-content-area">
                    <div className="prt-service-description">
                      <div className="prt-progressbar-main-block mt-30">
                        <div className="row align-items-center">
                          <div className="col-xl-8 col-lg-8">
                            <Content
                              title="What is a bone density scan?"
                              content={` A bone density scan, also known as a DEXA or DXA
                              scan, is a low-dose x-ray that measures calcium
                              and other minerals in your bones. Bones containing
                              more minerals are denser and tend to be stronger
                              and less likely to break. The measurement helps
                              show your bone's strength and thickness (known as
                              bone density or mass).
                              <br></br>
                              Your bones usually become thinner as you get older
                              or if you develop certain medical conditions. When
                              bones become thinner than usual, it's known as
                              osteopenia. Osteopenia puts you at risk for a more
                              serious condition called osteoporosis.
                              Osteoporosis is a disease that causes bones to
                              become very thin and brittle. It gets worse over
                              time. It usually affects older people and is most
                              common in women over the age of 65. People with
                              osteoporosis are at higher risk for fractures
                              (broken bones), especially in their hips, spine,
                              and wrists.
                              <br></br>
                              Other names: bone mineral density test, BMD test,
                              DEXA scan, DXA; Dual-energy x-ray absorptiometry
                              scan, Bone densitometry, Dual x-ray
                              absorptiometry, DXA`}
                            ></Content>
                          </div>
                          <div className="col-xl-4 col-lg-4">
                            <img
                              className="img-fluid border-rad_10"
                              src={service.image}
                              alt="primeprecision"
                            />
                          </div>
                          <div className="col-lg-12">
                            <h3 style={styles}>
                              What happens during a bone density scan?
                            </h3>
                            <p>
                              There are different ways to measure bone density.
                              The most common and accurate method is with
                              dual-energy x-ray absorptiometry, also known as a
                              central DEXA scan. The scan checks the bone
                              density of your lower spine, hip, wrist, or entire
                              body. It is usually done in a hospital or clinic.
                            </p>
                            <h3 style={styles}>
                              Will I need to do anything to prepare for the
                              test?
                            </h3>
                            <p>
                              You may be told to stop taking calcium supplements
                              24 to 48 hours before your test. You should also
                              avoid wearing metal jewelry or clothes with metal
                              parts, such as buttons or buckles.
                            </p>
                            <h3 style={styles}>
                              Are there any risks to the test?
                            </h3>
                            <p>
                              A bone density scan uses very low doses of
                              radiation. It is safe for most people. But it is
                              not recommended during pregnancy. Even low doses
                              of radiation could harm a developing baby. Be sure
                              to tell your provider if you are pregnant or think
                              you may be pregnant.
                            </p>
                            <h3 style={styles}>
                              Is there anything else I need to know about a bone
                              density scan?
                            </h3>
                            <p>
                              A DEXA scan is the most common way to measure bone
                              density. But your provider may order more tests to
                              confirm a diagnosis or to find out if bone loss
                              treatment is working. These include a calcium
                              blood test, a vitamin D test, and/or tests for
                              certain hormones.
                              <br></br>
                              DEXA scans are not as accurate if you have a
                              history of spinal surgery or spinal deformity,
                              fractures, or arthritis in your spine.
                            </p>
                            <h3 style={styles}>After the examination</h3>
                            <p>
                              The radiologist will review the images and provide
                              a written report to your referring doctor. Please
                              settle your account on the day of the examination.
                              Please contact Prime Precision Imaging for an
                              appointment on (+267) 316 3012
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <CategorySubcategory
                        name={service?.category_name}
                      ></CategorySubcategory>
                    </div>
                    <div className="col-lg-12">
                      <AppointmentForm></AppointmentForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* service-section end */}
        </div>{" "}
        {/*  site-main end */}
        <Footer></Footer>
      </div>
    </>
  );
}
