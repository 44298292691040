import React from "react";

export default function Stepper() {
  return (
    <section className="prt-row steps-section clearfix">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-5 col-md-12 prt-sticky-column">
            <div className="pr-40 pt-40 mb-30 res-1199-pr-0 res-1199-pt-0">
              <div className="section-title">
                <div className="title-header">
                  <h2 className="title">
                    3 steps for easy going to our patients
                  </h2>
                </div>
                <div className="title-desc">
                  <p>
                    Enjoy a stress-free journey to better well-being with our
                    carefully crafted three-step process, prioritizing your
                    convenience and comfort.
                  </p>
                </div>
                <div className="steps_btn mt-15 res-991-mt-0">
                  <a
                    className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-fill prt-btn-color-skincolor mr-20 mt-10"
                    href="/book-appointment"
                  >
                    Book Appointment
                  </a>
                  <a
                    className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-border prt-btn-color-darkcolor mt-10"
                    href="/contact-us"
                  >
                    Request callback
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-7 col-md-12">
            <div className="position-relative">
              <div className="featured-stepbox style1">
                <div className="featured-number">
                  <span className="number"></span>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>Schedule an Appointment</h3>
                  </div>
                  <div className="featured-desc">
                    <p>
                      Fill the booking form and select preferred date and time.
                    </p>
                  </div>
                </div>
              </div>

              <div className="featured-stepbox style1">
                <div className="featured-number">
                  <span className="number"></span>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>Upload Your Referral</h3>
                  </div>
                  <div className="featured-desc">
                    <p>
                      Your referral form is important. We need your doctor's
                      referral in advance of your appointment, in order to
                      prepare for your scan.{" "}
                      <a href="/for-referrers">Referral Form</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="featured-stepbox style1">
                <div className="featured-number">
                  <span className="number"></span>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>Prepare & Attend</h3>
                  </div>
                  <div className="featured-desc">
                    <p>
                      Please bring your printed referral form with you.please
                      bring those previous images and reports so that our
                      radiographer can provide the best comparative scans.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
