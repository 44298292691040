import React from "react";

export default function Map() {
  return (
    <section className="Map-section clearfix">
      <div className="container padding">
        <div className="row">
          <div className="col-xl-12 text-center">
            <div className="pr-40 pt-40 mb-30 res-1199-pr-0 res-1199-pt-0">
              <div className="section-title">
                <div className="title-header">
                  <h2 className="title">Locate Us</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-8  col-sm-12">
            <div className="google-map">
              <iframe
                title="Location Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14505.475507310575!2d25.9053537!3d-24.6454282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ebb5bc451b8b7bf%3A0x5161cd0b72f74d2a!2sPrime%20Precision%20Imaging!5e0!3m2!1sen!2sin!4v1724947091480!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0, marginBottom: 20 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
              {/* <iframe
                title="Location Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3626.294335222927!2d25.902825074793626!3d-24.64799510240972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ebb5b04018ff42b%3A0x121471c6690ae03c!2sRegus%20-%20Gaborone%2C%20iTowers!5e0!3m2!1sen!2sin!4v1724237500001!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe> */}
            </div>
          </div>
          <div className="col-xl-4 col-lg-4  col-sm-12 text-center">
            <img alt="office" height={450} src="/images/Home-map.png"></img>
          </div>
        </div>
      </div>
    </section>
  );
}
