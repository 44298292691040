import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet-async";
import { website } from "../constants/website";

export default function Privacy() {
  return (
    <>
      <Helmet>
        <title>{website.ABOUT.title}</title>
        <meta name="description" content={website.ABOUT.description} />
        <meta name="keywords" content={website.ABOUT.keywords} />
      </Helmet>
      <div className="page">
        <Header></Header>

        <div className="prt-page-title-row style2" style={{ marginTop: 90 }}>
          <div className="prt-page-title-row-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 m-auto z-index-9">
                  <div className="prt-page-title-row-heading text-center ">
                    <div className="page-title-heading">
                      <h2 className="title">Privacy Policy</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="site-main">
          <section className="prt-row banner-section bg-img1 prt-bg prt-bgimage-yes bg-base-dark clearfix">
            <div className="prt-row-wrapper-bg-layer prt-bg-layer bg-base-dark"></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 align-self-center mt-100 res-991-mt-0">
                  <div className="section-title style1">
                    <div className="title-header">
                      <h2 className="title">Privacy Policy</h2>
                    </div>
                    <div className="title-desc">
                      <p>
                        At Prime Precision Imaging, we value your privacy and
                        are committed to safeguarding your personal information.
                        This Privacy Policy explains how we collect, use,
                        disclose, and protect your information when you visit
                        our website or use our diagnostic imaging, healthcare,
                        and interventional procedure services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10">
                  <div className="section-title">
                    <div className="title-header">
                      <h4 className="title">1. Information We Collect</h4>
                      <p>We may collect the following types of information:</p>
                    </div>
                    <div className="title-desc">
                      <ul>
                        <li>
                          <h4>Personal Information: </h4>
                          <p>
                            Name, address, email, phone number, date of birth,
                            medical history, and any other information you
                            provide when scheduling an appointment or using our
                            services.
                          </p>
                        </li>

                        <li>
                          <h4>Health Information: </h4>
                          <p>
                            Medical records, imaging results, physician
                            referrals, and other health-related data required to
                            provide accurate diagnostic and interventional
                            services.
                          </p>
                        </li>

                        <li>
                          <h4>Usage Data: </h4>
                          <p>
                            Information on how you interact with our website,
                            including IP address, browser type, pages visited,
                            and the time and date of your visit.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="section-title">
                    <div className="title-header">
                      <h4 className="title">2. How We Use Your Information</h4>
                      <p>We use the information we collect to:</p>
                    </div>
                    <div className="title-desc">
                      <ul>
                        <li>
                          <p>
                            Provide and improve our diagnostic imaging and
                            healthcare services.
                          </p>
                        </li>
                        <li>
                          <p>
                            Communicate with you regarding appointments, test
                            results, and follow-up care.
                          </p>
                        </li>
                        <li>
                          <p>
                            Comply with legal obligations and medical standards.
                          </p>
                        </li>
                        <li>
                          <p>
                            Enhance user experience on our website and tailor
                            our services to meet your needs.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="section-title">
                    <div className="title-header">
                      <h4 className="title">3. Sharing Your Information</h4>
                      <p>
                        We do not sell or rent your personal information.
                        However, we may share your information with:
                      </p>
                    </div>
                    <div className="title-desc">
                      <ul>
                        <li>
                          <h4>Healthcare Providers: </h4>
                          <p>
                            Physicians, specialists, and other healthcare
                            professionals involved in your care.
                          </p>
                        </li>

                        <li>
                          <h4>Third-Party Service Providers:</h4>
                          <p>
                            Companies that assist us in providing services, such
                            as billing and data processing.
                          </p>
                        </li>

                        <li>
                          <h4>Legal Authorities:</h4>
                          <p>
                            If required by law, we may disclose your information
                            to comply with legal proceedings, court orders, or
                            regulatory requirements..
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="section-title">
                    <div className="title-header">
                      <h4 className="title">4. Security of Your Information</h4>
                    </div>
                    <p>
                      We implement industry-standard security measures to
                      protect your personal and health information from
                      unauthorized access, disclosure, alteration, or
                      destruction. However, no method of data transmission over
                      the internet is 100% secure, and we cannot guarantee
                      absolute security.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer></Footer>
      </div>
    </>
  );
}
