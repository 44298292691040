import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet-async";
import { website } from "../constants/website";

export default function OurKeyStaff() {
  return (
    <>
      <Helmet>
        <title>{website.ABOUT.title}</title>
        <meta name="description" content={website.ABOUT.description} />
        <meta name="keywords" content={website.ABOUT.keywords} />
      </Helmet>
      <div className="page">
        <Header></Header>

        <div className="prt-page-title-row style2" style={{ marginTop: 90 }}>
          <div className="prt-page-title-row-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 m-auto z-index-9">
                  <div className="prt-page-title-row-heading text-center ">
                    <div className="page-title-heading">
                      <h2 className="title">About US</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="site-main">
          <section className="prt-row about-section01 animation clearfix">
            <div className="container">
              {/*  row  */}
              <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="position-relative pl-30 res-1199-pl-0 res-1199-mt-30">
                    <div className="row row-equal-height">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        {/*  featured-contentbox  */}

                        <div className="featured-stepbox style1">
                          <div className="featured-number">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: 24 }}
                            ></i>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              <h3>Head of Administration</h3>
                            </div>
                            <div className="featured-desc">
                              <p>Kelebogile Ditlhong</p>
                            </div>
                          </div>
                        </div>

                        <div className="featured-stepbox style1">
                          <div className="featured-number">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: 24 }}
                            ></i>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              <h3>Front Desk Officer</h3>
                            </div>
                            <div className="featured-desc">
                              <p>Gokagiso Modikele </p>
                            </div>
                          </div>
                        </div>

                        {/*  featured-contentbox end */}
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6">
                        {/*  featured-contentbox  */}

                        <div className="featured-stepbox style1">
                          <div className="featured-number">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: 24 }}
                            ></i>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              <h3>Biller</h3>
                            </div>
                            <div className="featured-desc">
                              <p>Gorata Makwate</p>
                            </div>
                          </div>
                        </div>

                        <div className="featured-stepbox style1">
                          <div className="featured-number">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: 24 }}
                            ></i>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              <h3>Facility Custodian</h3>
                            </div>
                            <div className="featured-desc">
                              <p>Omphile Moikapari</p>
                            </div>
                          </div>
                        </div>

                        {/*  featured-contentbox end */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  row end  */}
            </div>
          </section>
        </div>

        <Footer></Footer>
      </div>
    </>
  );
}
