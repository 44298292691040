// {
//     id: "",
//     name: "",
//     desp:'',
//     icon: "",
//     image: "",
//     link: "",
//   },

export const services = [
  {
    id: "1",
    category: "Diagnostic Imaging",
    subheading: "",
    services: [
      {
        id: "1",
        name: "Ultrasound",
        desp: "Our ultrasound services offer non-invasive imaging to visualize organs and blood flow in real-time, helping diagnose conditions such as pregnancy complications, gallstones, and vascular diseases.",
        icon: "/images/services/ultrasonography.png",
        image: "/images/services/service-01.jpg",
        link: "/services/ultrasound",
        colsize: "3",
      },
      {
        id: "2",
        name: "Fluoroscopy",
        desp: "We utilize fluoroscopic imaging to observe moving internal structures, aiding in procedures like barium studies, joint injections, and gastrointestinal examinations.",
        icon: "images/services/fluoroscopy.png",
        image: "images/services/service-02.jpg",
        link: "/services/fluoroscopy",
        colsize: "3",
      },
      {
        id: "3",
        name: "Bone Densitometry (DEXA Scan)",
        desp: "We offer DEXA scans to assess one density and evaluate the risk of osteoporosis and fractures, providing early intervention and management recommendations",
        icon: "images/services/dexa-scan.png",
        image: "images/services/service-03.jpg",
        link: "/services/bone-densitometry-dexa-scan",
        colsize: "3",
      },
      {
        id: "4",
        name: "X-ray",
        desp: "Utilizing advanced digital radiography technology, we offer rapid and precise imaging for a variety of medical conditions, including fractures, lung disorders, and joint injuries.",
        icon: "images/services/dexa-scan.png",
        image: "images/services/service-04.jpg",
        link: "/services/x-ray",
        colsize: "3",
      },
    ],
  },
  {
    id: "3",
    category: "Women's Imaging",
    subheading: "",
    services: [
      {
        id: "2",
        name: "Breast Ultrasound",
        desp: "We offer supplementary breast imaging modalities to further evaluate breast abnormalities detected during mammography.",
        icon: "images/services/Breast-Ultrasound.png",
        image: "images/services/service-08.jpg",
        link: "/services/breast-ultrasound",
        colsize: "4",
      },
      {
        id: "1",
        name: "Mammography",
        desp: "We provide digital mammography services for early detection of breast cancer, supported by experienced radiologists and a compassionate care team.",
        icon: "images/services/mammography.png",
        image: "images/services/service-07.jpg",
        link: "/services/mammography",
        colsize: "4",
      },
      {
        id: "3",
        name: "3D Pregnancy Scan",
        desp: "We provide digital mammography services for early detection of breast cancer, supported by experienced radiologists and a compassionate care team.",
        icon: "images/services/pregnancy.png",
        image: "images/services/service-07a.jpg",
        link: "/services/3d-pregnancy-scan",
        colsize: "4",
      },
    ],
  },
  {
    id: "2",
    category: "Imaging Guided Procedures",
    subheading: "(ultrasound guided procedures)",
    services: [
      {
        id: "1",
        name: "Minimally Invasive Procedures",
        desp: "Our interventional radiology team performs a variety of minimally invasive procedures using image-guided techniques, including biopsies, drain insertions, and tumor ablations..",
        icon: "images/services/Minimally-Invasive.png",
        image: "images/services/service-05.jpg",
        link: "/services/minimally-invasive-procedure",
        colsize: "6",
      },
      {
        id: "2",
        name: "Pain Management",
        desp: "We offer advanced pain management procedures such as nerve blocks and spinal injections to alleviate pain and improve quality of life for our patients.",
        icon: "images/services/Pain-Management.png",
        image: "images/services/service-06.jpg",
        link: "/services/pain-management",
        colsize: "6",
      },
    ],
  },

  {
    id: "4",
    category: "Telemedicine and Teleradiology",
    subheading: "",
    services: [
      {
        id: "1",
        name: "Remote Reporting",
        desp: "Through our teleradiology services, we offer timely interpretation of imaging studies, enabling healthcare providers to access expert radiology consultation remotely.",
        icon: "images/services/teleradiology.png",
        image: "images/services/service-09.jpg",
        link: "/services/remote-reporting",
        colsize: "6",
      },
      {
        id: "2",
        name: "Advanced Technology",
        desp: "We invest in the latest imaging technologies and maintain rigorous quality assurance protocols to deliver accurate and reliable diagnostic results.",
        icon: "images/services/technology.png",
        image: "images/services/service-10.jpg",
        link: "/",
        colsize: "6",
      },
    ],
  },
];
