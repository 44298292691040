import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { services } from "../../constants/servicesDetails";
import AppointmentForm from "../../components/AppointmentForm";
import CategorySubcategory from "../../components/CategorySubcategory";
import HeadingBanner from "../../components/HeadingBanner";

export default function Fluoroscopy() {
  const service = services.find((service) => service.name === "breast-ultrasound");

  // Handle case where the service is not found

  if (!service) {
    return <p>Service not found.</p>;
  }

  const styles = { fontSize: 23, marginBottom: 7 };
  return (
    <>
      <div className="page">
        <Header></Header>
        {/* site-main start */}
        <div className="site-main">
          <HeadingBanner displayname={service.displayname}></HeadingBanner>

          {/* service-section */}
          <section
            className="prt-row section-top-bg clearfix "
            style={{ paddingRight: 80, paddingLeft: 80 }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="prt-service-single-content-area">
                    <div className="prt-service-description">
                      <div className="prt-progressbar-main-block mt-30">
                        <div className="row align-items-center">
                          <div className="col-xl-8 col-lg-8">
                            <h3 style={styles}>BREAST ULTRASOUND</h3>
                            <p>
                            Breast ultrasound is a non-invasive imaging technique that uses high-frequency sound waves to create detailed images of the breast tissue. It is often used in conjunction with mammography to provide additional information about breast abnormalities, such as lumps or changes detected during a screening or diagnostic mammogram.
                            </p>
                            <h3 style={styles} className="title">
                            Benefits of Breast Ultrasound
                            </h3>
                            <ul>
                              <li>Non-Invasive: Breast ultrasound is a painless and non-invasive procedure that does not involve radiation.</li>
                              <li>Detailed Imaging: It provides clear images of both solid and fluid-filled structures within the breast, helping to distinguish between benign cysts and solid masses.</li>
                              <li>Enhanced Diagnosis: It is particularly useful for examining dense breast tissue where mammography may be less effective.</li>
                              <li>Guided Biopsies: Ultrasound can be used to guide needle biopsies, ensuring precise targeting of suspicious areas for sampling.</li>
                            </ul>
                          
                          </div>
                          <div className="col-xl-4 col-lg-4">
                            <img
                              className="img-fluid border-rad_10"
                              src={service.image}
                              alt="primeprecision"
                            />
                          </div>
                          <div className="col-lg-12">
                            <h3 style={styles}>
                            PREPARATION
                            </h3>
                            <li>Wear Comfortable Clothing: Wear a two-piece outfit to allow easy access to your breast area.</li>
                            <li>Inform Us About Medications: Let us know if you are taking any medications or have any specific health concerns.</li>
                            <li>Discuss Recent Changes: Provide information about any recent changes in your breast health, such as new lumps or pain.</li>
                            
                          
                            <h3 style={styles}>After the examination</h3>
                            <p>
                              The radiologist will review the images and provide
                              a written report to your referring doctor. Please
                              settle your account on the day of the examination.
                              Please contact Prime Precision Imaging for an
                              appointment on (+267) 316 3012
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <CategorySubcategory
                        name={service?.category_name}
                      ></CategorySubcategory>
                    </div>
                    <div className="col-lg-12">
                      <AppointmentForm></AppointmentForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* service-section end */}
        </div>{" "}
        {/*  site-main end */}
        <Footer></Footer>
      </div>
    </>
  );
}
