import React from "react";

export default function Content({ title, content }: any) {
  const styles = { fontSize: 23, marginBottom: 7 };
  return (
    <>
      <h3 style={styles}>{title}</h3>
      <p dangerouslySetInnerHTML={{ __html: content }}></p>
    </>
  );
}
