import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { services } from "../../constants/servicesDetails";
import AppointmentForm from "../../components/AppointmentForm";
import CategorySubcategory from "../../components/CategorySubcategory";
import HeadingBanner from "../../components/HeadingBanner";

export default function MinimallyInvasiveProcedure() {
  const service = services.find((service) => service.id === "7");

  if (!service) {
    return <p>Service not found.</p>;
  }
  const styles = { fontSize: 23, marginBottom: 7 };
  return (
    <>
      <div className="page">
        <Header></Header>
        {/* site-main start */}
        <div className="site-main">
          <HeadingBanner displayname={service.displayname}></HeadingBanner>

          {/* service-section */}
          <section
            className="prt-row section-top-bg clearfix "
            style={{ paddingRight: 80, paddingLeft: 80 }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="prt-service-single-content-area">
                    <div className="prt-service-description">
                      <div className="prt-progressbar-main-block mt-30">
                        <div className="row align-items-center">
                          <div className="col-xl-8 col-lg-8">
                            <h3 style={styles}>MAMMOGRAPHY</h3>
                            <p>
                            Mammography is a specialized X-ray imaging technique used to screen for and diagnose breast conditions, including breast cancer. It involves taking detailed images of the breast tissue to identify any abnormalities or changes that may require further investigation.
                             <br></br>
                            </p>
                          </div>
                          <div className="col-xl-4 col-lg-4">
                            <img
                              className="img-fluid border-rad_10"
                              src={service.image}
                              alt="primeprecision"
                            />
                          </div>
                          <div className="col-lg-12">
                            <h3 style={styles}>
                            Our Mammography Services
                            </h3>
                           <ol>
                            <li><h3>Screening Mammograms</h3></li>
                            <p>Screening mammograms are routine examinations performed on women who have no symptoms but are at risk for breast cancer. These images help detect abnormalities before they become symptomatic, increasing the chances of successful treatment and recovery. We follow the latest guidelines to ensure your screening is thorough and timely.</p>
                            
                            <li><h3>Diagnostic Mammograms</h3></li>
                            <p>Diagnostic mammograms are conducted when there are symptoms or abnormal findings from a screening mammogram. These detailed images provide a closer look at areas of concern to help in the diagnosis of breast conditions. Our team uses advanced imaging techniques to ensure precise and accurate results.</p>
                           </ol>

                           <h3 style={styles}>
                           To ensure the best possible results, please follow these guidelines:
                            </h3>
                            <ul>
                              <li>Schedule Wisely: Try to schedule your mammogram for a time when your breasts are not tender, such as after your menstrual period.</li>
                              <li>Avoid Deodorants and Lotions: Do not use deodorants, antiperspirants, or lotions on your breasts or underarms before the exam, as they can interfere with the imaging.</li>
                              <li>Bring Previous Records: If you have had prior mammograms at another facility, please bring those records with you for comparison.</li>
                            </ul>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <CategorySubcategory
                        name={service?.category_name}
                      ></CategorySubcategory>
                    </div>
                    <div className="col-lg-12">
                      <AppointmentForm></AppointmentForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* service-section end */}
        </div>{" "}
        {/*  site-main end */}
        <Footer></Footer>
      </div>
    </>
  );
}
