import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { services } from "../../constants/servicesDetails";
import AppointmentForm from "../../components/AppointmentForm";
import CategorySubcategory from "../../components/CategorySubcategory";
import HeadingBanner from "../../components/HeadingBanner";

export default function Ultrasound() {
  const service = services.find((service) => service.name === "Ultrasound");

  if (!service) {
    return <p>Service not found.</p>;
  }

  const ultrasoundServices = [
    { name: "Abdomen (Upper)", duration: "30 mins" },
    { name: "Abdomen (Full)", duration: "40 mins" },
    { name: "Abdomen & Pelvis", duration: "45 mins" },
    { name: "Pelvis (Male)", duration: "30 mins" },
    { name: "Pelvis (Female)", duration: "40 mins" },
    { name: "IUD Placement", duration: "20 mins" },
    { name: "Liver", duration: "30 mins" },
    { name: "Gallbladder", duration: "20 mins" },
    { name: "Aorta Surveillance", duration: "20 mins" },
    { name: "Renal", duration: "20 mins" },
    { name: "Hernia", duration: "30 mins" },
    { name: "Thyroid", duration: "30 mins" },
    { name: "Scrotum", duration: "30 mins" },
    {
      name: "Breast",
      options: [
        { type: "Single", duration: "30 mins" },
        { type: "Double", duration: "40 mins" },
      ],
    },
    { name: "Soft Tissue", duration: "30 mins" },
    { name: "Salivary Glands", duration: "30 mins" },
  ];
  const styles = { fontSize: 23, marginBottom: 7 };
  return (
    <>
      <div className="page">
        <Header></Header>
        {/* site-main start */}
        <div className="site-main">
          <HeadingBanner displayname={service.displayname}></HeadingBanner>

          {/* service-section */}
          <section
            className="prt-row section-top-bg clearfix "
            style={{ paddingRight: 80, paddingLeft: 80 }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="prt-service-single-content-area">
                    <div className="prt-service-description">
                      <div className="prt-progressbar-main-block mt-30">
                        <div className="row align-items-center">
                          <div className="col-xl-8 col-lg-8">
                            <h4 style={{ fontSize: 23 }}>Timings</h4>
                            <ul>
                              {ultrasoundServices.map((us, i) => (
                                <li key={us.name}>
                                  {us.name} - {us.duration}{" "}
                                  {us?.options?.map((uso, i) => (
                                    <span>
                                      {uso.type} / {uso.duration}{" "}
                                    </span>
                                  ))}
                                </li>
                              ))}
                            </ul>
                            <p>
                              Please note that all timings are estimates only
                              due to operator and patient factors. Therefore
                              scanning times may vary.
                            </p>
                          </div>
                          <div className="col-xl-4 col-lg-4">
                            <img
                              className="img-fluid border-rad_10"
                              src={service.image}
                              alt="primeprecision"
                            />
                          </div>
                          <div className="col-lg-12">
                            <h3 style={styles}>What is Ultrasound</h3>
                            <p>
                              Our ultrasound services offer non-invasive imaging
                              to visualize organs and blood flow in real-time,
                              helping diagnose conditions such as pregnancy
                              complications,gallstones, and vascular diseases.
                            </p>
                            <h3 style={styles} className="title">
                              How to prepare for the test
                            </h3>
                            <p>
                              Your preparation is important, and will depend on
                              the part of the body being examined.{" "}
                            </p>
                            <h3 style={styles}>How long will my scan take?</h3>
                            <p>
                              Please note that all timings are estimates only.
                              Scanning times may vary due to operator and
                              patient factors. We allocate the time indicated
                              however many scans do not require the full time
                              given.
                              <br></br>
                              However, some scans can take longer than expected,
                              for example Anatomy scans where the baby's
                              position obstructs some of the views required to
                              complete the scan; or complex pelvic scans. This
                              added scanning work also requires additional time
                              for reporting.
                            </p>
                            <h3 style={styles}>After the examination</h3>
                            <p>
                              The radiologist will review the images and provide
                              a written report to your referring doctor. Please
                              settle your account on the day of the examination.
                              Please contact Prime Precision Imaging for an
                              appointment on (+267) 316 3012
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <CategorySubcategory
                        name={service?.category_name}
                      ></CategorySubcategory>
                    </div>
                    <div className="col-lg-12">
                      <AppointmentForm></AppointmentForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* service-section end */}
        </div>{" "}
        {/*  site-main end */}
        <Footer></Footer>
      </div>
    </>
  );
}
