import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { services } from "../constants/services";

export default function Services() {
  return (
    <>
      <div className="page">
        <Header></Header>
        <div className="prt-page-title-row style2" style={{ marginTop: 90 }}>
          <div className="prt-page-title-row-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <div className="prt-page-title-row-heading">
                    <div className="page-title-heading">
                      <h2 className="title">Our Services</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-main">
          {services.map((serv, i) => (
            <section
              className={`prt-row service-section01 clearfix ${
                i === 0 ? "mt-5" : ""
              }`}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xl-9 col-lg-12 m-auto">
                    <div className="section-title title-style-center_text">
                      <div className="title-header">
                        <h2 className="title" style={{ fontSize: 42 }}>
                          {serv.category}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-equal-height">
                  {serv.services.map((subserv, i) => (
                    <div
                      className={`col-xl-${subserv.colsize} col-lg-6 col-md-6 col-sm-12`}
                    >
                      <div className="featured-icon-box icon-align-before-content style4 bg-base-grey">
                        <div className="featured-icon">
                          <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-size-xl prt-icon_element-color-darkcolor">
                            <i className="icon-service">
                              <img src={subserv.icon} alt={subserv.name}></img>
                            </i>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>
                              <a href={subserv.link}>{subserv.name}</a>
                            </h3>
                          </div>
                          <div className="featured-desc">
                            <p>{subserv.desp}</p>
                            <div className="service-btn">
                              <a
                                className="prt-btn btn-inline prt-btn-size-md prt-btn-color-darkcolor underline"
                                href={subserv.link}
                              >
                                View more
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="services-thumbnail">
                          <a href={subserv.link}>
                            <img
                              width="267"
                              height="406"
                              src={subserv.image}
                              alt="primeprecision-img"
                              className="services-image img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          ))}
        </div>

        <Footer></Footer>
      </div>
    </>
  );
}
