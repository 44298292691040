import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { services } from "../../constants/servicesDetails";
import AppointmentForm from "../../components/AppointmentForm";
import CategorySubcategory from "../../components/CategorySubcategory";
import HeadingBanner from "../../components/HeadingBanner";

export default function MinimallyInvasiveProcedure() {
  const service = services.find((service) => service.id === "5");

  if (!service) {
    return <p>Service not found.</p>;
  }
  const styles = { fontSize: 23, marginBottom: 7 };
  return (
    <>
      <div className="page">
        <Header></Header>
        {/* site-main start */}
        <div className="site-main">
          <HeadingBanner displayname={service.displayname}></HeadingBanner>

          {/* service-section */}
          <section
            className="prt-row section-top-bg clearfix "
            style={{ paddingRight: 80, paddingLeft: 80 }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="prt-service-single-content-area">
                    <div className="prt-service-description">
                      <div className="prt-progressbar-main-block mt-30">
                        <div className="row align-items-center">
                          <div className="col-xl-8 col-lg-8">
                            <h3 style={styles}>OUR MINIMALLY INVASIVE SERVICES</h3>
                            <p>
                            Our image-guided biopsy techniques allow for accurate sampling of tissue for diagnostic purposes with minimal disruption. We use ultrasound, CT, or MRI to precisely target the area of interest, reducing the need for larger incisions and promoting quicker healing. 
                              <br></br>
                              <b>Ultrasound-Guided Biopsy: </b>Uses real-time ultrasound imaging to guide the needle to the target area.
                           </p>
                          </div>
                          <div className="col-xl-4 col-lg-4">
                            <img
                              className="img-fluid border-rad_10"
                              src={service.image}
                              alt="primeprecision"
                            />
                          </div>
                          <div className="col-lg-12">
                            <h3 style={styles}>
                            Benefits of Minimally Invasive Procedures
                            </h3>
                           <ul>
                            <li>Reduced Risk of Infection: Smaller incisions mean fewer opportunities for infections.</li>
                            <li>Quicker Recovery: Patients typically experience shorter recovery times and can return to their daily activities faster.</li>
                            <li>Less Pain: Minimal disruption to surrounding tissues results in less postoperative pain.</li>
                            <li>Shorter Hospital Stays: Many procedures are performed on an outpatient basis, allowing you to return home the same day.</li>
                           </ul>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <CategorySubcategory
                        name={service?.category_name}
                      ></CategorySubcategory>
                    </div>
                    <div className="col-lg-12">
                      <AppointmentForm></AppointmentForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* service-section end */}
        </div>{" "}
        {/*  site-main end */}
        <Footer></Footer>
      </div>
    </>
  );
}
