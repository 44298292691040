import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HeadingBanner from "../components/HeadingBanner";

export default function ForReferrers() {
  return (
    <div className="page">
      <Header></Header>

      {/* page-title  */}

      <div className="site-main">
        <HeadingBanner displayname="For Referrers"></HeadingBanner>
        {/* info-section end  */}

        {/* about-section  */}
        <section className="prt-row prt-bg  about-section01 clearfix">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-12">
                {/* section title  */}
                <div className="section-title">
                  <div className="title-header">
                    <h2 className="title">
                      Referring a patient to Prime Precision Imaging
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p className="prt-abouttext">
                      Our reception team will contact your patient to arrange
                      their scan appointment, ensuring it aligns with both the
                      patient’s availability and the appropriate specialist
                      radiologist’s schedule.
                    </p>
                    <p className="prt-abouttext">
                      The Referral Form is now accessible in both printable and
                      downloadable PDF formats for referrers’ convenience.
                    </p>
                  </div>
                </div>
                <a
                  className="prt-btn prt-btn-size-md prt-btn-style-fill prt-btn-color-skincolor mr-20 mt-10"
                  href="/images/PPI-Patient-Forms_RV.pdf" // Add the path to the file here
                  download="PPI-Patient-Forms_RV.pdf" // Optional: specify a custom file name
                >
                  DOWNLOAD REFERRAL FORM
                </a>
                {/* section title end  */}
              </div>
              <div className="col-xl-4 col-lg-12">
                <img
                  className="img-fluid border-rad_10"
                  src="/images/Referrers.jpg"
                  alt="primeprecision"
                />
              </div>
            </div>
          </div>
        </section>
        {/* about-section  */}
      </div>

      <Footer></Footer>
    </div>
  );
}
