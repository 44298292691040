import React from "react";

export default function AppointmentForm() {
  return (
    <>
      <div className="prt-contact-form" style={{ marginTop: 10 }}>
        <h3>Please book an appointment for a health consultation.</h3>
        <div className="prt-form-sep position-relative border-top mb-40 mt-30 res-575-mt-0 res-575-mb-0">
          <div className="prt-icon">
            <i className="flaticon-arrow"></i>
          </div>
        </div>
        <form
          action="#"
          className="query_form-1 wrap-form clearfix"
          method="post"
        >
          <div className="row">
            <div className="col-md-12">
              <span className="text-input">
                <input name="name" type="text" value="" placeholder="Name" />
              </span>
            </div>
            <div className="col-md-12">
              <span className="text-input">
                <input name="phone" type="text" value="" placeholder="Phone" />
              </span>
            </div>
            <div className="col-md-12">
              <span className="text-input">
                <input name="email" type="text" value="" placeholder="Email" />
              </span>
            </div>
            <div className="col-md-12">
              <span className="text-input select-option">
                <select id="doctor" name="user_state">
                  <option value="select-doctor">Looking for?</option>
                  <option value="Dr Tashinga Maboreke">
                    Dr Tashinga Maboreke
                  </option>
                  <option value="Dr Pearl Dube">Dr Pearl Dube</option>
                </select>
              </span>
            </div>
            <div className="col-md-12">
              <span className="text-input">
                <textarea
                  name="message"
                  rows={5}
                  placeholder="How can we help you?"
                ></textarea>
              </span>
            </div>

            <div className="col-md-12">
              <button
                className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-fill prt-btn-color-skincolor"
                type="submit"
              >
                Make an appointment
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
