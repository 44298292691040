import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { services } from "../../constants/servicesDetails";
import AppointmentForm from "../../components/AppointmentForm";
import CategorySubcategory from "../../components/CategorySubcategory";
import HeadingBanner from "../../components/HeadingBanner";

export default function Fluoroscopy() {
  const service = services.find((service) => service.name === "remote-reporting");

  // Handle case where the service is not found

  if (!service) {
    return <p>Service not found.</p>;
  }

  const styles = { fontSize: 23, marginBottom: 7 };
  return (
    <>
      <div className="page">
        <Header></Header>
        {/* site-main start */}
        <div className="site-main">
          <HeadingBanner displayname={service.displayname}></HeadingBanner>

          {/* service-section */}
          <section
            className="prt-row section-top-bg clearfix "
            style={{ paddingRight: 80, paddingLeft: 80 }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="prt-service-single-content-area">
                    <div className="prt-service-description">
                      <div className="prt-progressbar-main-block mt-30">
                        <div className="row align-items-center">
                          <div className="col-xl-8 col-lg-8">
                            <h3 style={styles}>REMOTE REPORTING</h3>
                            <p>
                            Remote reporting refers to the practice of radiologists analyzing and interpreting medical images from a location other than the imaging facility. Using advanced digital platforms and secure communication channels, our radiologists can provide detailed and accurate reports while maintaining the highest standards of privacy and security.
                            </p>
                            <h3 style={styles} className="title">
                            Benefits of Remote Reporting
                            </h3>
                            <ul>
                              <li><b>Enhanced Accessibility:</b> Remote reporting allows for prompt access to diagnostic interpretations, facilitating timely clinical decisions and improved patient care.</li>
                              <li><b>Flexibility:</b> Radiologists can work from various locations, ensuring that your imaging studies are reviewed and reported quickly, even outside regular office hours.</li>
                              <li><b>Expert Consultations:</b> Access to a wide network of radiology experts ensures that complex cases receive comprehensive evaluations and second opinions.</li>
                              <li><b>Efficient Workflow:</b> Streamlined reporting processes reduce turnaround times and support better coordination between healthcare providers.</li>
                            </ul>
                            </div>
                          <div className="col-xl-4 col-lg-4">
                            <img
                              className="img-fluid border-rad_10"
                              src={service.image}
                              alt="primeprecision"
                            />
                          </div>
                          <div className="col-lg-12">
                            <h3 style={styles}>
                            HOW IT WORKS
                            </h3>
                            <ol>
                            <li><b>Image Acquisition:</b> Your imaging studies are captured using our advanced diagnostic equipment.</li>
                            <li><b>Secure Transmission:</b> Images are transmitted securely to our remote reporting system.</li>
                            <li><b>Radiologist Review:</b> Our radiologists review and analyze the images, providing a detailed and accurate interpretation.</li>
                            <li><b>Report Generation:</b> A comprehensive report is generated and securely transmitted to your healthcare provider</li>
                            <li><b>Follow-Up:</b> Your healthcare provider will discuss the results with you and plan any necessary next steps.</li>
                            </ol>
                          
                            <h3 style={styles}>After the examination</h3>
                            <p>
                              The radiologist will review the images and provide
                              a written report to your referring doctor. Please
                              settle your account on the day of the examination.
                              Please contact Prime Precision Imaging for an
                              appointment on (+267) 316 3012
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <CategorySubcategory
                        name={service?.category_name}
                      ></CategorySubcategory>
                    </div>
                    <div className="col-lg-12">
                      <AppointmentForm></AppointmentForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* service-section end */}
        </div>{" "}
        {/*  site-main end */}
        <Footer></Footer>
      </div>
    </>
  );
}
