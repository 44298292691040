import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { services } from "../constants/servicesDetails";
import { useSearchParams } from "react-router-dom";

interface Category {
  name: string;
  subcategories: string[];
}

export default function ServicesDetails() {
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");
  const [activecategory, setActiveCategory] = React.useState("");
  const service = services.find((service) => service.name === name);

  // Handle case where the service is not found
  if (!service) {
    return <p>Service not found.</p>;
  }
  const toggleCategory = (category: string) => {
    setActiveCategory(category);
  };

  const categories: Category[] = [
    {
      name: "Diagnostic Imaging",
      subcategories: [
        "Ultrasound",
        "X-ray",
        "Fluoroscopy",
        "Bone Densitometry (DEXA Scan)",
      ],
    },
    {
      name: "Interventional Radiology",
      subcategories: ["Minimally Invasive Procedures", "Pain Management"],
    },
    {
      name: "Womens Imaging",
      subcategories: ["Mammography", "Breast Ultrasound"],
    },
    {
      name: "Telemedicine and Teleradiology",
      subcategories: ["Remote Reporting", "Advanced Technology"],
    },
  ];

  return (
    <>
      <div className="page">
        <Header></Header>
        {/* site-main start */}
        <div className="site-main">
          <div className="prt-page-title-row style2" style={{ marginTop: 90 }}>
            <div className="prt-page-title-row-inner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 m-auto z-index-9">
                    <div className="prt-page-title-row-heading text-center ">
                      <div className="page-title-heading">
                        <h2 className="title">{service.name}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* service-section */}
          <section
            className="prt-row section-top-bg clearfix "
            style={{ paddingRight: 80, paddingLeft: 80 }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="prt-service-single-content-area">
                    <div className="prt-service-description">
                      <div className="prt-progressbar-main-block mt-30">
                        <div className="row align-items-center">
                          <div className="col-xl-8 col-lg-8">
                            {/* {service.description.map((sd, i) => (
                              <>
                                <div key={sd.id}>
                                  {" "}
                                  <h3
                                    style={{ fontSize: 23, marginBottom: 7 }}
                                    className="title"
                                  >
                                    {sd.title}
                                  </h3>
                                  <p>{sd.desp}</p>
                                </div>
                              </>
                            ))} */}
                          </div>
                          <div className="col-xl-4 col-lg-4">
                            <img
                              className="img-fluid border-rad_10"
                              src={service.image}
                              alt="primeprecision"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-equal-height mt-30 res-991-mt-0">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="featured-imagebox featured-imagebox-service style2">
                            <div className="featured-thumbnail">
                              <img
                                width="120"
                                height="120"
                                className="img-fluid"
                                src="/images/services/service-02.jpg"
                                alt="img"
                              />
                            </div>
                            <div className="featured-content">
                              <div>
                                <div className="featured-title">
                                  <h3>
                                    <a href="/">Cardiac imaging</a>
                                  </h3>
                                </div>
                                <div className="featured-desc">
                                  <p>
                                    Coronary angiography and cardiac MRI for
                                    heart
                                  </p>
                                </div>
                              </div>
                              <div className="prt-service-btn">
                                <a
                                  className="prt-btn btn-inline prt-icon-btn-right prt-btn-size-md prt-btn-color-darkcolor"
                                  href="/"
                                >
                                  View more
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="featured-imagebox featured-imagebox-service style2">
                            <div className="featured-thumbnail">
                              <img
                                width="120"
                                height="120"
                                className="img-fluid"
                                src="/images/services/service-04.jpg"
                                alt="img"
                              />
                            </div>
                            <div className="featured-content">
                              <div>
                                <div className="featured-title">
                                  <h3>
                                    <a href="/">The angiography</a>
                                  </h3>
                                </div>
                                <div className="featured-desc">
                                  <p>
                                    This test uses the contrast x-rays for the
                                    heart
                                  </p>
                                </div>
                              </div>
                              <div className="prt-service-btn">
                                <a
                                  className="prt-btn btn-inline prt-icon-btn-right prt-btn-size-md prt-btn-color-darkcolor"
                                  href="/"
                                >
                                  View more
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="sidebar prt-row">
                        <ul className="category-list">
                          {categories.map((category, index) => (
                            <li key={index}>
                              <div
                                className={`category ${
                                  activecategory === category.name
                                    ? "category-active"
                                    : ""
                                }`}
                                onClick={() => toggleCategory(category.name)}
                              >
                                {category.name}
                              </div>
                              <ul
                                className={`subcategory-list ${
                                  activecategory === category.name ? "open" : ""
                                }`}
                              >
                                {category.subcategories.map(
                                  (subcategory, subIndex) => (
                                    <li key={subIndex}>
                                      <a href="/">{subcategory}</a>
                                    </li>
                                  )
                                )}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div
                        className="prt-contact-form"
                        style={{ marginTop: 10 }}
                      >
                        <h3>Schedule a free health consultation</h3>
                        <div className="prt-form-sep position-relative border-top mb-40 mt-30 res-575-mt-0 res-575-mb-0">
                          <div className="prt-icon">
                            <i className="flaticon-arrow"></i>
                          </div>
                        </div>
                        <form
                          action="#"
                          className="query_form-1 wrap-form clearfix"
                          method="post"
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <span className="text-input">
                                <input
                                  name="name"
                                  type="text"
                                  value=""
                                  placeholder="Name"
                                />
                              </span>
                            </div>
                            <div className="col-md-12">
                              <span className="text-input">
                                <input
                                  name="phone"
                                  type="text"
                                  value=""
                                  placeholder="Phone"
                                />
                              </span>
                            </div>
                            <div className="col-md-12">
                              <span className="text-input">
                                <input
                                  name="email"
                                  type="text"
                                  value=""
                                  placeholder="Email"
                                />
                              </span>
                            </div>
                            <div className="col-md-12">
                              <span className="text-input select-option">
                                <select id="doctor" name="user_state">
                                  <option value="select-doctor">
                                    Looking for?
                                  </option>
                                  <option value="doctor1">
                                    Dr. Howard holmes
                                  </option>
                                  <option value="doctor2">
                                    Dr. Sarah rose
                                  </option>
                                  <option value="doctor3">
                                    Dr. Broklyn simm
                                  </option>
                                  <option value="doctor4">
                                    Dr. Garreth mills
                                  </option>
                                  <option value="doctor5">
                                    Dr. Maria gordian
                                  </option>
                                  <option value="doctor6">
                                    Dr. Natalia zox
                                  </option>
                                </select>
                              </span>
                            </div>
                            <div className="col-md-12">
                              <span className="text-input">
                                <textarea
                                  name="message"
                                  rows={5}
                                  placeholder="How can we help you?"
                                ></textarea>
                              </span>
                            </div>
                            <div className="col-lg-12">
                              <span className="text-input d-flex align-items-center headingfont-color mb-20">
                                <input
                                  className="mr-10"
                                  type="checkbox"
                                  name="cookies-consent"
                                  id="cookies-consent1"
                                />
                                Schedule a free health consultation
                              </span>
                            </div>
                            <div className="col-md-12">
                              <button
                                className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-fill prt-btn-color-skincolor"
                                type="submit"
                              >
                                Make an appointment
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* service-section end */}
        </div>{" "}
        {/*  site-main end */}
        <Footer></Footer>
      </div>
    </>
  );
}
