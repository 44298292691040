import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet-async";
import { website } from "../constants/website";

export default function Team() {
  return (
    <>
      <Helmet>
        <title>{website.TEAM.title}</title>
        <meta name="description" content={website.ABOUT.description} />
        <meta name="keywords" content={website.ABOUT.keywords} />
      </Helmet>
      <div className="page">
        <Header></Header>
        {/* page-title */}
        {/* page-title */}
        <div className="prt-page-title-row style2" style={{ marginTop: 90 }}>
          <div className="prt-page-title-row-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <div className="prt-page-title-row-heading">
                    <div className="page-title-heading">
                      <h2 className="title">Our Radiologists</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* page-title end-->

  {/* site-main start */}
        <div className="site-main">
          <section className="prt-row team-section clearfix">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="team-details-content">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="team-details-image-wrapper">
                          <img
                            width="100%"
                            height="445"
                            className="img-fluid team-details-image"
                            src="images/tashinga-1.jpg"
                            alt="primeprecision"
                          />
                        </div>
                      </div>
                      <div className="col-lg-8 align-self-center res-991-mt-30">
                        <div className="team-details-info">
                          <div className="team-details-info-title">
                            <h3>Dr Tashinga Maboreke</h3>
                          </div>
                          <div className="team-details-info-position">
                            <span>Special surgeon & Radiologist</span>
                          </div>
                          <div className="team-details-info-desc">
                            <p>
                              He has went through extensive training beyond
                              general radiology, including fellowships and
                              advanced courses, to gain expertise . His
                              knowledge in interpreting images such as X-rays,
                              CT scans, MRI scans and ultrasounds allows him to
                              provide more detailed and accurate diagnoses,
                              often handling complex or rare conditions within
                              their area of expertise. Dr T. Maboreke’s role is
                              crucial in guiding treatment plans and making
                              decisions about further diagnostic tests or
                              procedures as he works closely with other
                              healthcare professionals, including surgeons and
                              oncologists, to ensure comprehensive patient care.
                            </p>
                          </div>
                          <ul className="prt-list style3">
                            <li className="prt-list-item">
                              <span>Experience :</span>
                              <span>25 years</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mt-30">
                  <div className="team-details-content">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="team-details-image-wrapper">
                          <img
                            width="100%"
                            height="445"
                            className="img-fluid team-details-image"
                            src="images/doctor.png"
                            alt="primeprecision"
                          />
                        </div>
                      </div>
                      <div className="col-lg-8 align-self-center res-991-mt-30">
                        <div className="team-details-info">
                          <div className="team-details-info-title">
                            <h3>Pearl Dube</h3>
                          </div>
                          <div className="team-details-info-position">
                            <span>Lead Radiographer</span>
                          </div>
                          <div className="team-details-info-desc">
                            <p>
                              “My passion for radiography is driven by a desire
                              to contribute to accurate diagnostics and improved
                              patient outcomes. I am committed to delivering
                              excellence in every aspect of my role and
                              continually striving to enhance my skills and
                              knowledge in the field. My journey in radiography
                              began with a Bachelor’s degree in Radiologic
                              Technology, where I acquired a comprehensive
                              understanding of radiographic principles, patient
                              care, and imaging techniques.”
                            </p>
                            <p>
                              Her role is vital in the diagnostic process,
                              providing essential information that helps in
                              diagnosing injuries, diseases, and other medical
                              conditions. She contribute to effective patient
                              care by delivering precise imaging services and
                              maintaining high standards of safety and
                              professionalism.
                            </p>
                          </div>
                          <ul className="prt-list style3">
                            <li className="prt-list-item">
                              <span>Experience :</span>
                              <span>25 years</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer></Footer>
      </div>
    </>
  );
}
