import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { services } from "../../constants/servicesDetails";
import AppointmentForm from "../../components/AppointmentForm";
import CategorySubcategory from "../../components/CategorySubcategory";
import HeadingBanner from "../../components/HeadingBanner";

export default function Fluoroscopy() {
  const service = services.find((service) => service.name === "Fluroscopy");

  // Handle case where the service is not found

  if (!service) {
    return <p>Service not found.</p>;
  }

  const styles = { fontSize: 23, marginBottom: 7 };
  return (
    <>
      <div className="page">
        <Header></Header>
        {/* site-main start */}
        <div className="site-main">
          <HeadingBanner displayname={service.displayname}></HeadingBanner>

          {/* service-section */}
          <section
            className="prt-row section-top-bg clearfix "
            style={{ paddingRight: 80, paddingLeft: 80 }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="prt-service-single-content-area">
                    <div className="prt-service-description">
                      <div className="prt-progressbar-main-block mt-30">
                        <div className="row align-items-center">
                          <div className="col-xl-8 col-lg-8">
                            <h3 style={styles}>What is fluoroscopy?</h3>
                            <p>
                              Fluoroscopy is a type of x-ray that shows organs,
                              tissues, or other internal structures moving in
                              real time. Standard x-rays are like still
                              photographs. Fluoroscopy is like a movie. It shows
                              body systems in action. These include the
                              cardiovascular (heart and blood vessels),
                              digestive, and reproductive systems. The procedure
                              can help your health care provider evaluate and
                              diagnose a variety of conditions.
                            </p>
                            <h3 style={styles} className="title">
                              Why do I need fluoroscopy?
                            </h3>
                            <p>
                              You may need a fluoroscopy if your provider wants
                              to check the function of a particular organ,
                              system, or other internal part of your body. You
                              may also need fluoroscopy for certain medical
                              procedures that require imaging.
                            </p>
                          </div>
                          <div className="col-xl-4 col-lg-4">
                            <img
                              className="img-fluid border-rad_10"
                              src={service.image}
                              alt="primeprecision"
                            />
                          </div>
                          <div className="col-lg-12">
                            <h3 style={styles}>
                              Will I need to do anything to prepare for the
                              test?
                            </h3>
                            <p>
                              Your preparation will depend on the type of
                              fluoroscopy procedure. For some procedures, you
                              don't need any special preparations. For others,
                              you may be asked to avoid certain medicines and/or
                              to fast (not eat or drink) for several hours
                              before the test. Your provider will let you know
                              if you need to do any special preparations.
                            </p>
                            <h3 style={styles}>
                              Are there any risks to the test?
                            </h3>
                            <p>
                              You should not have a fluoroscopy procedure if you
                              are pregnant or think you may be pregnant.
                              Radiation can be harmful to an unborn baby.
                              <br></br>
                              For others, there is little risk to having this
                              test. The dose of radiation depends on the
                              procedure, but fluoroscopy is not considered
                              harmful for most people. But talk to your provider
                              about all the x-rays you've had in the past. The
                              risks from radiation exposure may be linked to the
                              number of x-ray treatments you've had over time.
                              <br></br>
                              If you will be having contrast dye, there is a
                              small risk of an allergic reaction. Tell your
                              provider if you have any allergies, especially to
                              shellfish or iodine, or if you've ever had a
                              reaction to contrast material.
                            </p>
                            <h3 style={styles}>After the examination</h3>
                            <p>
                              The radiologist will review the images and provide
                              a written report to your referring doctor. Please
                              settle your account on the day of the examination.
                              Please contact Prime Precision Imaging for an
                              appointment on (+267) 316 3012
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <CategorySubcategory
                        name={service?.category_name}
                      ></CategorySubcategory>
                    </div>
                    <div className="col-lg-12">
                      <AppointmentForm></AppointmentForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* service-section end */}
        </div>{" "}
        {/*  site-main end */}
        <Footer></Footer>
      </div>
    </>
  );
}
