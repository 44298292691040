import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { services } from "../../constants/servicesDetails";
import AppointmentForm from "../../components/AppointmentForm";
import CategorySubcategory from "../../components/CategorySubcategory";
import HeadingBanner from "../../components/HeadingBanner";

export default function Xray() {
  const service = services.find((service) => service.name === "x-ray");

  // Handle case where the service is not found

  if (!service) {
    return <p>Service not found.</p>;
  }

  const styles = { fontSize: 23, marginBottom: 7 };
  return (
    <>
      <div className="page">
        <Header></Header>
        {/* site-main start */}
        <div className="site-main">
          <HeadingBanner displayname={service.displayname}></HeadingBanner>

          {/* service-section */}
          <section
            className="prt-row section-top-bg clearfix "
            style={{ paddingRight: 80, paddingLeft: 80 }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="prt-service-single-content-area">
                    <div className="prt-service-description">
                      <div className="prt-progressbar-main-block mt-30">
                        <div className="row align-items-center">
                          <div className="col-xl-8 col-lg-8">
                            <h3 style={styles}>What is a X-ray?</h3>
                            <p>
                              X-rays, or plain radiographs, are a way for
                              providers to get pictures of the inside of your
                              body. X-rays use radiation to create
                              black-and-white images that a radiologist reads.
                              X-rays are most commonly used to look at bones and
                              joints, but providers can use them to quickly
                              diagnose other conditions, like lung infections,
                              too.
                            </p>
                            <h3 style={styles}>
                              What is a general or plain X-Ray?
                            </h3>
                            <p>
                              Radiography involves creating images of the body’s
                              internal structures using X-rays, a type of
                              radiation similar to visible light, radio waves,
                              and microwaves. What makes X-rays unique is their
                              high energy, which allows the X-ray beam to pass
                              through the body and produce an image.
                              <br></br> <br></br>
                              The image forms because different body parts
                              absorb X-rays at varying levels. Dense materials
                              like bones absorb more of the X-ray beam,
                              appearing as light grey on the image, while less
                              dense tissues, like muscles, absorb less,
                              resulting in darker grey areas. The varying
                              densities of these structures give rise to a clear
                              picture of the body's internal components.
                            </p>
                          </div>
                          <div className="col-xl-4 col-lg-4">
                            <img
                              className="img-fluid border-rad_10"
                              src={service.image}
                              alt="primeprecision"
                            />
                          </div>
                          <div className="col-lg-12">
                            <h3 style={styles}>
                              How do I prepare for a plain X-Ray?
                            </h3>
                            <p>
                              For a standard X-ray, there are typically no
                              special preparations required. However, it is
                              essential to bring the X-ray request form or
                              referral letter from your doctor, as an X-ray
                              cannot be conducted without this documentation.
                              <br></br>
                              <br></br>
                              It’s important to notify the radiographer
                              performing the X-ray if there’s any possibility
                              you might be pregnant. Ensuring the safety of both
                              the patient and the unborn child is the top
                              priority, and an alternative test or approach
                              might be necessary. Some types of clothing can
                              obscure the images, so you may need to wear a
                              provided gown. Additionally, be prepared to remove
                              items like watches, jewelry, and certain clothing
                              with metal components like zippers, as these can
                              affect image clarity.
                            </p>
                            <h3 style={styles}>
                              What are the risks of plain X-Rays?
                            </h3>
                            <p>
                              Generally, the benefit of the X-Ray procedure is
                              far more important than the small estimated risk.
                              At the radiation dose levels that are used in
                              diagnostic radiography there is little or no
                              evidence of health effects.
                              <br></br>
                              <br></br>
                              The two major risks to health that occur as a
                              result of exposure to medical ionizing radiation
                              (which is the kind of radiation in X-Rays) are:
                              <ul>
                                <li>
                                  Cancer occurring many years after the
                                  radiation exposure
                                </li>
                                <li>
                                  Health problems in the children born to people
                                  exposed to radiation because of damage to the
                                  reproductive cells in the body.
                                </li>
                              </ul>
                              To put this into perspective, a patient would need
                              to have approximately 38 chest X-Rays to receive
                              an amount of radiation similar to that of normal
                              background radiation that everyone receives for
                              one year from the environment.
                            </p>

                            <h3 style={styles}>After the examination</h3>
                            <p>
                              The radiologist will review the images and provide
                              a written report to your referring doctor. Please
                              settle your account on the day of the examination.
                              Please contact Prime Precision Imaging for an
                              appointment on (+267) 316 3012
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <CategorySubcategory
                        name={service?.category_name}
                      ></CategorySubcategory>
                    </div>
                    <div className="col-lg-12">
                      <AppointmentForm></AppointmentForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* service-section end */}
        </div>{" "}
        {/*  site-main end */}
        <Footer></Footer>
      </div>
    </>
  );
}
