import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet-async";
import { website } from "../constants/website";
import { services } from "../constants/servicesDetails";

export default function ContactUs() {
  return (
    <>
      <Helmet>
        <title>{website.CONTACT.title}</title>
        <meta name="description" content={website.CONTACT.description} />
        <meta name="keywords" content={website.CONTACT.keywords} />
      </Helmet>
      <div className="page">
        <Header></Header>

        <div className="site-main">
          <section className="prt-row prt-bg bg-base-grey contact-section contact-border-top clearfix">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-8 col-md-10 col-sm-11 m-auto">
                  <div className="section-title title-style-center_text">
                    <div className="title-header">
                      <h3 className="prt-contact-pretitle">Contact</h3>
                      <h2 className="title" style={{ fontSize: 40 }}>
                        Schedule an Appointment
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="prt_contact_widget_wrapper mt-55 res-991-mt-20">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="prt_contact-title-block bg-base-gradient">
                          <div className="section-title">
                            <div className="title-header text-base-white">
                              <h2 className="title">Our booking policy</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="prt_content-block">
                        <div className="row g-0">
                          <div className="col-xl-6 col-lg-12">
                            <div className="pr-40 res-991-pr-0">
                              <p>A few things to note-</p>
                              <ul>
                                <li>
                                  We'll complete payment before your
                                  appointment, so please arrive 5-10 minutes
                                  before your scheduled booking.
                                </li>
                                <li>
                                  If your insurance provider is covering the
                                  scan and you are using a non affiliated
                                  insurance provider**, it's important to send
                                  confirmation of payment prior to the scan, or
                                  to forward the provider's guarantee of
                                  payment, with details
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-12">
                            <div
                              className="prt-contact-form"
                              data-aos="fade-up"
                              data-aos-duration="1000"
                            >
                              <h3>Schedule an Appointment</h3>
                              <div className="prt-form-sep position-relative border-top mb-40 mt-30 res-575-mt-0 res-575-mb-0">
                                <div className="prt-icon">
                                  <i className="flaticon-arrow"></i>
                                </div>
                              </div>
                              <form
                                action="#"
                                className="query_form-1 wrap-form clearfix"
                                method="post"
                              >
                                <div className="row">
                                  <div className="col-md-6">
                                    <span className="text-input">
                                      <input
                                        name="name"
                                        type="text"
                                        value=""
                                        placeholder="Name"
                                      />
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <span className="text-input">
                                      <input
                                        name="name"
                                        type="text"
                                        value=""
                                        placeholder="Surname"
                                      />
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <span className="text-input">
                                      <input
                                        name="dob"
                                        type="date"
                                        value=""
                                        placeholder="Date of Birth"
                                      />
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <span className="text-input">
                                      <input
                                        name="phone"
                                        type="text"
                                        value=""
                                        placeholder="Phone Number"
                                      />
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <span className="text-input">
                                      <input
                                        name="phone"
                                        type="text"
                                        value=""
                                        placeholder="Alternative Number"
                                      />
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <span className="text-input">
                                      <input
                                        name="email"
                                        type="text"
                                        value=""
                                        placeholder="Email"
                                      />
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <span className="text-input select-option">
                                      <select id="doctor" name="user_state">
                                        <option value="">
                                          Service requested
                                        </option>
                                        {services.map((s, i) => (
                                          <option value={s.displayname}>
                                            {s.displayname}
                                          </option>
                                        ))}
                                      </select>
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <span className="text-input select-option">
                                      <select id="doctor" name="user_state">
                                        <option value="">Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                      </select>
                                    </span>
                                  </div>

                                  <div className="col-md-6">
                                    <span className="text-input select-option">
                                      <select id="doctor" name="user_state">
                                        <option value="">Payment Mode</option>
                                        <option value="Bpomas">Bpomas</option>
                                        <option value="pula">Pula</option>
                                        <option value="cash">Cash</option>
                                      </select>
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <label>Referral Form</label>
                                    <input
                                      name="referrral"
                                      type="file"
                                      value=""
                                      placeholder="Referral Form"
                                    />
                                  </div>
                                  <div className="col-md-12 mt-2">
                                    <span className="text-input">
                                      <textarea
                                        name="message"
                                        placeholder="How can we help you?"
                                      ></textarea>
                                    </span>
                                  </div>

                                  <div className="col-md-12">
                                    <button
                                      className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-fill prt-btn-color-skincolor"
                                      type="submit"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer></Footer>
      </div>
    </>
  );
}
