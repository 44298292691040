import React from "react";

export default function Banner() {
  return (
    <>
      <div className="hero-section clearfix">
        <div className="hero-slider-wrapper">
          <div className="hero-slider prt-slider">
            <div className="hero-slide slide-1">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    <div className="hero-content-block">
                      <div className="hero-content">
                        <div className="pretitle slideInDown">
                          <h3>Prime Precision Imaging</h3>
                        </div>
                        <h2 style={{ fontSize: 40 }} className="title">
                          Compassionate Care
                        </h2>
                        <div className="hero-desc">
                          <p>
                            Accurate Results | Modern Technology | Compassionate
                            Service
                          </p>
                        </div>
                        {/* <div className="d-flex align-items-center mt-25">
                          <div className="hero-slider_btn">
                            <a
                              className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-border prt-btn-color-darkcolor"
                              href="contact-us.html"
                            >
                              Request an appointment
                            </a>
                          </div>

                          <div className="featured-icon-box icon-align-before-content style1">
                            <div className="featured-icon">
                              <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-size-lg prt-icon_element-color-gradientcolor">
                                <i className="flaticon flaticon-test"></i>
                              </div>
                            </div>
                            <div className="featured-content">
                              <div className="featured-desc">
                                <p>Your Health Our priority</p>
                              </div>
                              <div className="featured-title">
                                <h3>
                                  <a href="contact-us.html">Get consultant</a>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-slide slide-2">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    <div className="hero-content-block">
                      <div className="hero-content">
                        <h2 className="title">
                          Medical imaging
                          <br />& <span>Radiology</span> center
                        </h2>
                        <div className="hero-desc">
                          <p>
                            Accurate Results | Modern Technology | Compassionate
                            Service
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
