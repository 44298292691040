import React from "react";
import { services } from "../../constants/services";

export default function Services() {
  return (
    <section
      className="prt-row prt-bg bg-base-grey pricingplan-section clearfix"
      style={{ paddingTop: 40 }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="prt-pricing-main-block position-relative">
              <div className="section-title">
                <div className="title-header">
                  <h2 className="title">Specialized check-up Services</h2>
                </div>
              </div>

              <div className="prt-tabs prt-tab-style-01">
                <ul className="tabs">
                  {services.map((serv, i) => (
                    <li className={`tab ${i === 0 ? "active" : ""}`}>
                      <a href="/">{serv.category}</a>
                    </li>
                  ))}
                </ul>

                <div
                  className="content-tab"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="content-inner active">
                    <div className="row row-equal-height fadeup-amin">
                      {services[0].services.map((sub, i) => (
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                          <div className="featured-icon-box icon-align-before-content style4">
                            <div className="featured-icon">
                              <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-size-xl prt-icon_element-color-darkcolor">
                                <i className="icon-service">
                                  <img src={sub.icon} alt={sub.name}></img>
                                </i>
                              </div>
                            </div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h3>
                                  <a href={sub.link}>{sub.name}</a>
                                </h3>
                              </div>
                              <div className="featured-desc">
                                <p>{sub.desp}</p>
                                <div className="service-btn">
                                  <a
                                    className="prt-btn btn-inline prt-btn-size-md prt-btn-color-darkcolor underline"
                                    href={sub.link}
                                  >
                                    View more
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="services-thumbnail">
                              <a href={sub.link}>
                                <img
                                  width="267"
                                  height="406"
                                  src={sub.image}
                                  alt="primeprecision-img"
                                  className="services-image img-fluid"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="content-inner">
                    <div className="row row-equal-height">
                      {services[1].services.map((sub, i) => (
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                          <div className="featured-icon-box icon-align-before-content style4">
                            <div className="featured-icon">
                              <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-size-xl prt-icon_element-color-darkcolor">
                                <i className="icon-service">
                                  <img src={sub.icon} alt={sub.name}></img>
                                </i>
                              </div>
                            </div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h3>
                                  <a href={sub.link}>{sub.name}</a>
                                </h3>
                              </div>
                              <div className="featured-desc">
                                <p>{sub.desp}</p>
                                <div className="service-btn">
                                  <a
                                    className="prt-btn btn-inline prt-btn-size-md prt-btn-color-darkcolor underline"
                                    href={sub.link}
                                  >
                                    View more
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="services-thumbnail">
                              <a href={sub.link}>
                                <img
                                  width="267"
                                  height="406"
                                  src={sub.image}
                                  alt="primeprecision-img"
                                  className="services-image img-fluid"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="content-inner">
                    <div className="row row-equal-height">
                      {services[2].services.map((sub, i) => (
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="featured-icon-box icon-align-before-content style4">
                            <div className="featured-icon">
                              <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-size-xl prt-icon_element-color-darkcolor">
                                <i className="icon-service">
                                  <img src={sub.icon} alt={sub.name}></img>
                                </i>
                              </div>
                            </div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h3>
                                  <a href={sub.link}>{sub.name}</a>
                                </h3>
                              </div>
                              <div className="featured-desc">
                                <p>{sub.desp}</p>
                                <div className="service-btn">
                                  <a
                                    className="prt-btn btn-inline prt-btn-size-md prt-btn-color-darkcolor underline"
                                    href={sub.link}
                                  >
                                    View more
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="services-thumbnail">
                              <a href={sub.link}>
                                <img
                                  width="267"
                                  height="406"
                                  src={sub.image}
                                  alt="primeprecision-img"
                                  className="services-image img-fluid"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="content-inner">
                    <div className="row row-equal-height">
                      {services[3].services.map((sub, i) => (
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="featured-icon-box icon-align-before-content style4">
                            <div className="featured-icon">
                              <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-size-xl prt-icon_element-color-darkcolor">
                                <i className="icon-service">
                                  <img src={sub.icon} alt={sub.name}></img>
                                </i>
                              </div>
                            </div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h3>
                                  <a href={sub.link}>{sub.name}</a>
                                </h3>
                              </div>
                              <div className="featured-desc">
                                <p>{sub.desp}</p>
                                <div className="service-btn">
                                  <a
                                    className="prt-btn btn-inline prt-btn-size-md prt-btn-color-darkcolor underline"
                                    href={sub.link}
                                  >
                                    View more
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="services-thumbnail">
                              <a href={sub.link}>
                                <img
                                  width="267"
                                  height="406"
                                  src={sub.image}
                                  alt="primeprecision-img"
                                  className="services-image img-fluid"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
