export const services = [
  {
    id: "1",
    name: "Ultrasound",
    displayname: "Ultrasound",
    category_name: "Diagnostic Imaging",
    image: "/images/services/service-01.jpg",
  },

  {
    id: "2",
    name: "Fluroscopy",
    displayname: "Fluroscopy",
    category_name: "Diagnostic Imaging",
    image: "/images/services/service-02.jpg",
  },

  {
    id: "3",
    name: "bone-densitometry",
    displayname: "Bone Densitometry (DEXA Scan)",
    category_name: "Diagnostic Imaging",
    image: "/images/services/service-03.jpg",
  },
  {
    id: "3",
    name: "x-ray",
    displayname: "X-ray",
    category_name: "Diagnostic Imaging",
    image: "/images/services/service-04.jpg",
  },

  {
    id: "5",
    name: "minimally-invasive-procedure",
    displayname: "Minimally Invasive Procedures",
    category_name: "Imaging Guided Procedures",
    image: "/images/services/service-05.jpg",
  },
  {
    id: "6",
    name: "pain-management",
    displayname: "Pain Management",
    category_name: "Imaging Guided Procedures",
    image: "/images/services/service-06.jpg",
  },
  {
    id: "7",
    name: "mammography",
    displayname: "Mammography",
    category_name: "Women's Imaging",
    image: "/images/services/service-07.jpg",
  },
  {
    id: "8",
    name: "breast-ultrasound",
    displayname: "Breast Ultrasound",
    category_name: "Women's Imaging",
    image: "/images/services/service-08.jpg",
  },
  {
    id: "9",
    name: "remote-reporting",
    displayname: "Remote Reporting",
    category_name: "Telemedicine and Teleradiology",
    image: "/images/services/service-09.jpg",
  },
  {
    id: "10",
    name: "advanced-technology",
    displayname: "Advanced Technology",
    category_name: "Telemedicine and Teleradiology",
    image: "/images/services/service-10.jpg",
  },

  {
    id: "11",
    name: "3D-Pregnancy",
    displayname: "3D Pregnancy Scan",
    category_name: "Women's Imaging",
    image: "/images/services/service-07a.jpg",
  },
];
