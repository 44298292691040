import React from "react";

export default function ContactInfo() {
  return (
    <>
      <section className="prt-row prt-bg bg-base-gradient info-section01 clearfix">
        <div className="container">
          <div className="row prt-vertical_sep row-equal-height">
            <div className="col-lg-3  col-md-6 col-sm-6 col-12">
              <div className="featured-icon-box icon-align-before-content style3">
                <div className="featured-icon">
                  <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-size-lg prt-icon_element-color-whitecolor">
                    <i className="flaticon flaticon-pin focus-yellow"></i>
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a
                        rel="noreferrer"
                        href="https://maps.app.goo.gl/kGpxrUDc8QVeTtt87"
                        target="_blank"
                      >
                        Locate Us
                      </a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>
                      plot 54734 grand union building CBD,
                      <br />
                      unit 2B on the new lobatse road
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3  col-md-6 col-sm-6 col-12">
              <div className="featured-icon-box icon-align-before-content style3">
                <div className="featured-icon">
                  <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-size-lg prt-icon_element-color-whitecolor">
                    <i className="fa fa-phone focus-yellow"></i>
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href="tel:+2673163012">Call us directly</a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>
                      <span className="focus-yellow">Tel :</span> (+267) 316
                      3012 <br /> <span className="focus-yellow"> cell :</span>{" "}
                      (+267) 785 94919 (Whatsapp){" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3  col-md-6 col-sm-6 col-12">
              <div className="featured-icon-box icon-align-before-content style3">
                <div className="featured-icon">
                  <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-size-lg prt-icon_element-color-whitecolor">
                    <i className="fa fa-envelope focus-yellow"></i>
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href="mailto:info@primeprecision.co.bw">
                        Email Support
                      </a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>info@primeprecision.co.bw</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3  col-md-6 col-sm-6 col-12">
              <div className="featured-icon-box icon-align-before-content style3">
                <div className="featured-icon">
                  <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-size-lg prt-icon_element-color-whitecolor">
                    <i className="flaticon flaticon-pdf-file focus-yellow"></i>
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href="/for-referrers">For Referrers</a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>Submit Your Referral Form</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
