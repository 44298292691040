import React from "react";

export default function Footer() {
  return (
    <footer className="footer widget-footer clearfix">
      <div className="second-footer">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-3 col-sm-6 col-6">
              <div className="footer-widget-box">
                <h3 className="widget-title-h3">Quick Links</h3>
                <ul className="menu-footer-link">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About us</a>
                  </li>
                  <li>
                    <a href="/services">Services</a>
                  </li>
                  <li>
                    <a href="/for-referrers">For Referrers</a>
                  </li>

                  <li>
                    <a href="/contact-us">Contact us</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-4 col-lg-3 col-md-3 col-sm-6 col-6">
              <div className="footer-widget-box res-767-mt-30">
                <h3 className="widget-title-h3">Popular Services</h3>
                <ul className="menu-footer-link">
                  <li>
                    <a href="/services/ultrasound">Ultrasound</a>
                  </li>
                  <li>
                    <a href="/services/x-ray">X-ray</a>
                  </li>
                  <li>
                    <a href="/services/pain-management">Pain Management</a>
                  </li>
                  <li>
                    <a href="/services/mammography">Mammography</a>
                  </li>
                  <li>
                    <a href="/services/breast-ultrasound">Breast Ultrasound</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
              <div className="footer-widget-box res-1199-mt-30">
                <div className="footer-widget-img">
                  <a href="/">
                    <img
                      width="60%"
                      height="auto"
                      className="img-fluid"
                      src="/images/PrimePrecisionLogo-1.png"
                      alt="image-i"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="copyright-text">
                <div className="cpy-text">
                  <p className="mb-0">
                    Copyright © {new Date().getFullYear()}{" "}
                    <a href="/">Prime Precision</a> All rights reserved.
                  </p>
                </div>
                <ul className="footer-nav-menu">
                  <li>
                    <a href="/terms-condition"> Terms and conditions</a>
                  </li>
                  <li>
                    <a href="/privacy"> Privacy policy </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
